import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loginStart } from '../../store/slices/AdminSlice';
import { ButtonLoader } from '../Helper/Loader';
import EmailVerification from './EmailVerification';
import { useState } from 'react';
import { useEffect } from 'react';
import TwoStepAuthentication from './TwoStepAuthentication';

const Login = () => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const login = useSelector(state => state.admin.login);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [showTFA, setShowTFA] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      console.log(login.data);
      if (login.data.email_status === 0) setShowVerify(login.data);
      else if (login.data.tfa_status === 1) setShowTFA(login.data);
    }
    setSkipRender(false);
  }, [login]);

  const handleLogin = values => {
    dispatch(loginStart(values));
  }

  return (
    <>
      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <Link to="/login" className="flex items-center space-x-2">
          <img className="h-12 w-12" src={window.location.origin + "/images/app-logo.png"} alt="logo" />
          <p className="text-xl font-semibold uppercase text-slate-700 dark:text-navy-100">
            Haive
          </p>
        </Link>
      </div>
      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img className="w-full" id="hero-image-light" src={window.location.origin + "/images/illustrations/dashboard-check.svg"} alt="image" />
          <img className="w-full hidden" id="hero-image-dark" src={window.location.origin + "/images/illustrations/dashboard-check-dark.svg"} alt="image" />
        </div>
      </div>
      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
            <img className="mx-auto h-16 w-16 lg:hidden" src={window.location.origin + "/images/app-logo.png"} alt="logo" />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                {t("heading")}
              </h2>
              <p className="text-slate-400 dark:text-navy-300">
                {t("message")}
              </p>
            </div>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              remember: false,
            }}
            validationSchema={loginSchema}
            onSubmit={handleLogin}
          >
            {({ setFieldValue, values }) =>
              <FORM noValidate>
                <div className="mt-16">
                  <label className="relative flex">
                    <Field
                      className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                      placeholder={t("email.placeholder")}
                      type="text"
                      autoFocus={true}
                      name="email"
                    />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                    </span>
                  </label>
                  <ErrorMessage
                    component={"div"}
                    name="email"
                    className="errorMsg"
                  />
                  <label className="relative mt-4 flex">
                    <Field
                      className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                      placeholder={t("password.placeholder")}
                      type="password"
                      name="password"
                    />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                      </svg>
                    </span>
                  </label>
                  <ErrorMessage
                    component={"div"}
                    name="password"
                    className="errorMsg"
                  />
                  <div className="mt-4 flex items-center justify-between space-x-2">
                    <label className="inline-flex items-center space-x-2">
                      <input
                        className="form-checkbox is-outline h-5 w-5 rounded border-slate-400/70 bg-slate-100 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-500 dark:bg-navy-900 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                        type="checkbox"
                        checked={values.remember}
                        onChange={() => setFieldValue("remember", !values.remember)}
                      />
                      <span className="line-clamp-1">{t("remember")}</span>
                    </label>
                    {/* <Link
                      to="/forgot-password"
                      className="text-xs text-slate-400 transition-colors line-clamp-1 hover:text-slate-800 focus:text-slate-800 dark:text-navy-300 dark:hover:text-navy-100 dark:focus:text-navy-100">
                      {t("forgot")}
                    </Link> */}
                  </div>
                  <button
                    type="submit"
                    className="btn mt-10 h-10 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    disabled={login.buttonDisable}>
                    {login.buttonDisable ? <ButtonLoader /> : t("login_btn.text")}
                  </button>
                </div>
              </FORM>
            }
          </Formik>
          <div className="mt-4 text-center text-xs+">
            <p className="line-clamp-1">
              <span>{t("create_account")}</span>
              <Link
                to="/register"
                className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                {t("register")}
              </Link>
            </p>
          </div>
        </div>
      </main>
      {showVerify ?
        <EmailVerification email={showVerify.email} remember={showVerify.remember} closeVerify={() => setShowVerify(false)} />
        : null}
      {showTFA ?
        <TwoStepAuthentication email={showTFA.email} remember={showTFA.remember} closeVerify={() => setShowTFA(false)} />
        : null}
    </>
  );
}

export default Login;