import React, { Fragment, memo, useCallback, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addChat, setChats, setSelectedChats, updateChatMessages, updateChatSuggtion } from "../../store/slices/ChatSlice";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useSocket from "../../hooks/useSocket";
import { ButtonLoader } from "../Helper/Loader";
import { apiUrl } from "../../Environment";
import EmojiPicker from 'emoji-picker-react';
import { useDropzone } from 'react-dropzone'
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
dayjs.extend(customParseFormat);

const ChatBox = ({ chatId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const selectedChats = useSelector(state => state.chat.selectedChats);
  const highlightChat = useSelector(state => state.chat.highlightChat);
  const chats = useSelector(state => state.chat.chats);

  const [singleMsg, setSingleMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [isMin, setIsMin] = useState(false);
  const [first, setFirst] = useState(true);
  const [notify, setNotify] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [cursorPointer, setCursorPointer] = useState(0);
  const [skipRender, setSkipRender] = useState(true);
  const lastMsg = useRef();
  const messageField = useRef();

  const { subscribe, reconnect, update, sendMessage, unSubscribe } = useSocket();
  const [chat, setChat] = useState({ messages: [], chatId, notify: false });

  useEffect(() => {
    setChat(chats.find(c => c.chatId === chatId) ?? chat);
  }, [chats]);

  const token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token");

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      getInitialChat();
    }
  }, [profile]);

  const getInitialChat = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("chat_id", chatId);
    const response = await axios.post(`${apiUrl}chats/messages`, formData, { headers: { Authorization: 'Bearer ' + token } });
    if (response.data) {
      console.log(response.data);
      setLoading(false);
      dispatch(addChat({
        chatId,
        username: response.data.data.chat.username,
        chat_unique_id: response.data.data.chat.chat_unique_id,
        autoMsg: false,
        notify: true,
        messages: response.data.data.chat_messages,
        socketStatus: 0,
      }));
      subscribe(response.data.data.chat.username);

    } else {
      setLoading(false);
      setShowChat(false);
    }
  }

  useEffect(() => {
    if (chat.messages && lastMsg.current) {
      lastMsg.current.scrollIntoView({ behavior: first ? "instant" : "smooth" })
      setFirst(false);
    }
  }, [chat.messages]);

  useEffect(() => {
    if (chat.suggetion && chat.socketStatus === 1) {
      setSingleMsg(msg => msg ? msg + "\n\n" + chat.suggetion : chat.suggetion);
      dispatch(updateChatSuggtion({ username: chat.username, suggetion: "" }));
    }
  }, [chat.suggetion]);

  useEffect(() => {
    if (!first && chat.socketStatus === 1)
      setNotify(true);
  }, [chat.notify]);


  const onSend = async (e) => {
    e.preventDefault();
    if (singleMsg && !sendLoading) {
      setShowEmoji(false);
      setSendLoading(true);
      let formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("answer", singleMsg);
      try {
        const response = await axios.post(`${apiUrl}hooks/send_message`, formData, { headers: { Authorization: 'Bearer ' + token } });
        if (response.data.success) {
          console.log(response.data);
          const message = response.data.data.chat_message;
          const date = dayjs().format('DD MMM YYYY');
          dispatch(updateChatMessages({
            username: response.data.data.chat_formatted.username,
            message: { date, message },
            disableNotify: true,
          }));
          setSingleMsg("");
          sendMessage(response.data.data.chat_formatted.username, { date, message });
        }
        else {
          console.log(response);
          getErrorNotificationMessage(response.data.error);
        }
        setSendLoading(false);
      } catch (e) {
        setSendLoading(false);
        getErrorNotificationMessage(e);
      }
    }
  }

  const onSendFile = async (file) => {
    if (!fileLoading) {
      setShowEmoji(false);
      setFileLoading(true);
      let formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("image", file);
      try {
        const response = await axios.post(`${apiUrl}hooks/send_message`, formData, { headers: { Authorization: 'Bearer ' + token } });
        if (response.data.success) {
          console.log(response.data);
          const message = response.data.data.chat_message;
          const date = dayjs().format('DD MMM YYYY');
          dispatch(updateChatMessages({
            username: response.data.data.chat_formatted.username,
            message: { date, message },
            disableNotify: true,
          }));
          setSingleMsg("");
          sendMessage(response.data.data.chat_formatted.username, { date, message });
        }
        else {
          console.log(response);
          getErrorNotificationMessage(response.data.error);
        }
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        getErrorNotificationMessage(false);
      }
    }
  }

  const removeChat = () => {
    dispatch(setSelectedChats(selectedChats.filter(chat_id => chat_id != chatId)));
    setTimeout(() => {
      unSubscribe(chat.username);
    }, 500);
  }

  const changeAutoMsg = () => update(chat.username);

  useEffect(() => {
    if (!skipRender) {
      messageField.current.selectionEnd = cursorPointer;
    }
    setSkipRender(false);
  }, [cursorPointer]);

  const onEmojiPick = (data) => {
    const ref = messageField.current;
    ref.focus();
    const start = singleMsg.substring(0, ref.selectionStart);
    const end = singleMsg.substring(ref.selectionStart);
    const text = start + data.emoji + end;
    setSingleMsg(text);
    setCursorPointer(start.length + data.emoji.length);
  }


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      onSendFile(file);
    })

  }, [])
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.gif']
    },
    maxFiles: 1,
    noDragEventsBubbling: true
  })

  return (
    loading ?
      <div className={`client-chat-popout-sec ${highlightChat === chatId ? "highlight" : ""}`}>
        <div className="client-chat-popout-header-sec">
          <div className="client-chat-popout-ip-info-sec">
            <div className="client-chat-popout-ip-info">
              <h4 onClick={() => setIsMin(!isMin)}>Loading...</h4>
            </div>
          </div>
          <div className="client-chat-popout-ip-icon-right">
            {/* <button
              className="client-chat-popout-ip-icon-btn"
              onClick={() => {
                navigate(`/chats/show/${chatId}`);
                removeChat();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                fill="#fff"
                viewBox="0 0 24 24"
              >
                <path d="M22.5 9A1.5 1.5 0 0024 7.5v-4A3.5 3.5 0 0020.5 0h-4a1.5 1.5 0 000 3h2.376L12 9.879 5.121 3H7.5a1.5 1.5 0 000-3h-4A3.5 3.5 0 000 3.5v4a1.5 1.5 0 003 0V5.121L9.879 12 3 18.879V16.5a1.5 1.5 0 00-3 0v4A3.5 3.5 0 003.5 24h4a1.5 1.5 0 000-3H5.121L12 14.121 18.879 21H16.5a1.5 1.5 0 100 3h4a3.5 3.5 0 003.5-3.5v-4a1.5 1.5 0 00-3 0v2.376L14.121 12 21 5.121V7.5A1.5 1.5 0 0022.5 9z"></path>
              </svg>
            </button> */}
            <button
              className="client-chat-popout-ip-icon-btn"
              onClick={() => removeChat()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="#fff"
                x="0"
                y="0"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
              >
                <path d="M342.635 169.365c-12.493-12.501-32.754-12.507-45.255-.014l-.015.014L256 210.752l-41.365-41.387c-12.501-12.501-32.769-12.501-45.269 0s-12.501 32.769 0 45.269L210.752 256l-41.387 41.365c-12.501 12.501-12.501 32.769 0 45.269 12.501 12.501 32.769 12.501 45.269 0L256 301.248l41.365 41.387c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269L301.248 256l41.387-41.365c12.501-12.493 12.507-32.754.014-45.255l-.014-.015z"></path>
                <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm0 448c-106.039 0-192-85.961-192-192S149.961 64 256 64s192 85.961 192 192c-.118 105.99-86.01 191.882-192 192z"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="client-chat-popout-body-sec"></div>
      </div>
      : showChat && chat.chat_unique_id ?
        <div className={`client-chat-popout-sec ${highlightChat === chatId ? "highlight" : ""}`}>
          <div className="client-chat-popout-header-sec">
            <div className="client-chat-popout-ip-info-sec">
              <div className="client-chat-popout-ip-info">
                {notify ?
                  <span className="notify-position flex h-4 w-4 items-center justify-center">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80"></span>
                    <span className="inline-flex h-2 w-2 rounded-full bg-secondary"></span>
                  </span>
                  : null
                }
                <h4 onClick={() => setIsMin(!isMin)}>
                  {chat.chat_unique_id}
                </h4>
              </div>
            </div>
            <div className="client-chat-popout-ip-icon-right">
              {chat.socketStatus === 1 ?
                <input
                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:!bg-success checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:before:bg-white"
                  type="checkbox"
                  checked={chat.autoMsg}
                  onChange={() => changeAutoMsg()}
                  data-tooltip-id="tooltip-success"
                  data-tooltip-content="Auto Message"
                  data-tooltip-place="bottom"
                />
                : <input
                  className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:!bg-error checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:before:bg-white"
                  type="checkbox"
                  checked={true}
                  onChange={() => reconnect(chat.username)}
                />
              }
              <button
                className="client-chat-popout-ip-icon-btn"
                onClick={() => {
                  navigate(`/chats/show/${chatId}`);
                  removeChat();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="#fff"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.5 9A1.5 1.5 0 0024 7.5v-4A3.5 3.5 0 0020.5 0h-4a1.5 1.5 0 000 3h2.376L12 9.879 5.121 3H7.5a1.5 1.5 0 000-3h-4A3.5 3.5 0 000 3.5v4a1.5 1.5 0 003 0V5.121L9.879 12 3 18.879V16.5a1.5 1.5 0 00-3 0v4A3.5 3.5 0 003.5 24h4a1.5 1.5 0 000-3H5.121L12 14.121 18.879 21H16.5a1.5 1.5 0 100 3h4a3.5 3.5 0 003.5-3.5v-4a1.5 1.5 0 00-3 0v2.376L14.121 12 21 5.121V7.5A1.5 1.5 0 0022.5 9z"></path>
                </svg>
              </button>
              <button
                className="client-chat-popout-ip-icon-btn"
                onClick={() => removeChat()}
                data-tooltip-id="tooltip-error"
                data-tooltip-content="Close"
                data-tooltip-place="bottom"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="#fff"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 512 512"
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <path d="M342.635 169.365c-12.493-12.501-32.754-12.507-45.255-.014l-.015.014L256 210.752l-41.365-41.387c-12.501-12.501-32.769-12.501-45.269 0s-12.501 32.769 0 45.269L210.752 256l-41.387 41.365c-12.501 12.501-12.501 32.769 0 45.269 12.501 12.501 32.769 12.501 45.269 0L256 301.248l41.365 41.387c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269L301.248 256l41.387-41.365c12.501-12.493 12.507-32.754.014-45.255l-.014-.015z"></path>
                  <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm0 448c-106.039 0-192-85.961-192-192S149.961 64 256 64s192 85.961 192 192c-.118 105.99-86.01 191.882-192 192z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="client-chat-popout-body-sec" style={{ display: isMin ? "none" : "block" }}
            onClick={() => setNotify(false)}
            onFocus={() => setNotify(false)}
            {...getRootProps()}
          >
            {chat.messages.length > 0 ?
              <>
                {chat.messages.map((dailyChat, i) =>
                  <Fragment key={i}>
                    <div className="client-chat-sticky-date-time">
                      {dailyChat.date}
                    </div>
                    {dailyChat.messages.map((message, i) => message.sent_by === "user" ?
                      <div className="client-chat-user-receiver-card" key={i}>
                        <div className="client-chat-user-receiver-icon-sec">
                          <img
                            className="client-chat-popout-ip-icon"
                            src={window.location.origin + "/images/user.png"}
                          />
                        </div>
                        <div className="client-chat-user-receiver-message-card">
                          <div className="client-chat-user-receiver-message">
                            {message.file ?
                              <div className="my-2">
                                <CustomLazyLoad
                                  src={message.file}
                                />
                              </div>
                              : null
                            }
                            <div dangerouslySetInnerHTML={{
                              __html: urlify(message.message),
                            }} />
                          </div>
                          <div className="client-caht-user-receiver-time">
                            {message.send_at}
                          </div>
                        </div>
                      </div>
                      : <div className="client-chat-user-sender-card" key={i}>
                        <div className="client-chat-user-sender-icon-sec">
                          B
                        </div>
                        <div className="client-chat-user-sender-message-card">
                          <div className="client-chat-user-sender-message">
                            {message.file ?
                              <div className="my-2">
                                <CustomLazyLoad
                                  src={message.file}
                                />
                              </div>
                              : null
                            }
                            <div dangerouslySetInnerHTML={{
                              __html: urlify(message.message),
                            }} />
                          </div>
                          <div className="client-caht-user-sender-time">
                            {message.send_at}
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
                <div ref={lastMsg} />
              </>
              : null
            }

            {chat.socketStatus === 1 ?
              <div className="client-chat-popout-card-left-footer-form-1">
                <form onSubmit={onSend}>
                  {showEmoji ?
                    <EmojiPicker onEmojiClick={onEmojiPick} />
                    : null
                  }
                  <div>
                    <label className="flex -space-x-px">
                      <ChatMessageBox
                        value={singleMsg}
                        onChange={e => setSingleMsg(e.target.value)}
                        textareaRef={messageField}
                      />
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <button type="button" onClick={() => setShowEmoji(!showEmoji)} style={{ fontSize: "25px" }} className="mx-2">
                        🙂
                      </button>
                      <button type="button" onClick={() => !fileLoading ? open() : null}>
                        {fileLoading ?
                          <i className="fa fa-upload fa-beat-fade upload-anim" />
                          : <svg height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 280.067 280.067">
                            <g>
                              <path fill="#4F46E5" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429
		c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0
		s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394
		c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599
		c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403
		c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485
		c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098
		l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
                            </g>
                          </svg>
                        }
                      </button>
                      <input {...getInputProps()} disabled={fileLoading} />
                    </div>
                    <button
                      className="btn bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 mx-2 my-2"
                      type="submit"
                    >
                      {sendLoading ?
                        <ButtonLoader size={5} />
                        : <>
                          <span className="mx-2">Send</span>
                          {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="#fff"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                        </svg> */}
                        </>
                      }
                    </button>
                  </div>
                </form>
              </div>
              : chat.socketStatus === 2 ?
                <div className="client-chat-popout-card-left-footer-form-1">
                  <div
                    className="form-control w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 chat-msg"
                  >
                    <div className="text-error w-full">Monitoring mode</div>
                    Chat is already connected in another device
                  </div>
                  <button
                    className="btn w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                    type="button"
                    onClick={() => reconnect(chat.username)}
                  >
                    Make this Active
                  </button>
                </div>
                : null
            }
            {isDragActive && chat.socketStatus === 1 && !fileLoading ?
              <div className="w-full flex justify-center items-center drop-zone">
                <p className="drop-msg text-center">Drop the files here to upload. <br />
                  jpg, png, gif
                </p>
              </div>
              : null
            }
          </div>
        </div>
        : "Error"
  );
}


const ChatMessageBox = ({ value, onChange, textareaRef }) => {
  // const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    console.log(scrollHeight);
    textareaRef.current.style.height = scrollHeight < 76 ? "76px" : scrollHeight > 192 ? "192px" : scrollHeight + "px";
  }, [value]);

  return <textarea
    ref={textareaRef}
    className="form-control w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 chat-msg"
    placeholder="Write your message"
    type="text"
    value={value}
    onChange={onChange}
  />
}

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replaceAll("\n", "<br />");
  return text.replaceAll(urlRegex, function (url) {
    return '<a className="text-info underline" href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export default memo(ChatBox);