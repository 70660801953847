import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../store/slices/AdminSlice';
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import { settingsJsonStart } from '../../store/slices/AdminSlice';
// Layouts
import { AuthLayout, EmptyLayout, AdminLayout } from '../Layout';
// Pages
import Page404 from '../Helper/Page404';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import DashboardIndex from '../Dashboard/DashboardIndex';
import CategoryIndex from '../Category/CategoryIndex';
import CategoryForm from '../Category/CategoryForm';
import CategoryView from '../Category/CategoryView';
import ProfileIndex from '../Profile/ProfileIndex';
import SubscriptionIndex from '../Subscription/SubscriptionIndex';
import SubscriptionView from '../Subscription/SubscriptionView';
import SubscriptionForm from '../Subscription/SubscriptionForm';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import ChatIndex from '../Chat/ChatIndex';
import SingleChat from '../Chat/SingleChat';
import FineTuningIndex from '../FineTuning/FineTuningIndex';

setTranslations({ en, es });

const CommonRoute = ({
  component: Component,
  authLayout: AuthLayout,
  adminLayout: AdminLayout,
  ...rest
}) => localStorage.getItem("token") || sessionStorage.getItem("token") ?
    <AdminLayout {...rest}>
      <Component />
    </AdminLayout>
    : <AuthLayout {...rest}>
      <Component />
    </AuthLayout>

const AppRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => localStorage.getItem("token") || sessionStorage.getItem("token") ?
    <Navigate to={{ pathname: "/" }} />
    : <Layout {...rest}>
      <Component />
    </Layout>;

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => localStorage.getItem("token") || sessionStorage.getItem("token") ?
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
    : <Navigate to={{ pathname: "/login" }} />;

const Base = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.admin.theme);
  const profile = useSelector(state => state.admin.profile);
  const settingsJson = useSelector(state => state.admin.settingsJson);

  const [, setState] = useState();

  useEffect(() => {
    if (localStorage.getItem("dark-mode"))
      dispatch(setTheme(localStorage.getItem("dark-mode")));
    dispatch(settingsJsonStart());
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("dark-mode", theme)
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.removeItem("dark-mode");
    }
  }, [theme]);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    setState(1);
  }, [profile]);

  return (
    settingsJson.loading ?
      <PageLoader />
      : Object.keys(settingsJson.data).length > 0 ?
        <>
          <BrowserRouter>
            <Routes>
              <Route
                path={"/login"}
                element={
                  <AppRoute
                    component={Login}
                    layout={AuthLayout}
                  />
                }
              />
              <Route
                path={"/register"}
                element={
                  <AppRoute
                    component={Register}
                    layout={AuthLayout}
                  />
                }
              />
              <Route
                path={"/forgot-password"}
                element={
                  <AppRoute
                    component={ForgotPassword}
                    layout={AuthLayout}
                  />
                }
              />
              <Route
                path={"/reset-password/:token"}
                element={
                  <AppRoute
                    component={ResetPassword}
                    layout={AuthLayout}
                  />
                }
              />
              <Route path={"/"}>
                <Route
                  index={true}
                  element={
                    <PrivateRoute
                      component={DashboardIndex}
                      layout={AdminLayout}
                    />
                  }
                />

                <Route
                  path={"profile"}
                  element={
                    <PrivateRoute
                      component={ProfileIndex}
                      layout={AdminLayout}
                    />
                  }
                />
              </Route>

              {/* SUBSCRIPTIONS */}
              <Route path={"/subscriptions"}>
                <Route
                  index={true}
                  element={
                    <PrivateRoute
                      component={SubscriptionIndex}
                      layout={AdminLayout}
                    />
                  }
                />
                <Route
                  path={"show/:subscriptionUniqueId"}
                  element={
                    <PrivateRoute
                      component={SubscriptionView}
                      layout={AdminLayout}
                    />
                  }
                />
                <Route
                  path={"create"}
                  element={
                    <PrivateRoute
                      component={SubscriptionForm}
                      layout={AdminLayout}
                    />
                  }
                />
              </Route>

              {/* CHAT */}
              <Route path="/chats">
                <Route
                  index={true}
                  element={
                    <PrivateRoute
                      component={ChatIndex}
                      layout={AdminLayout}
                    />
                  }
                />
                <Route
                  path={"show/:chatId"}
                  element={
                    <PrivateRoute
                      component={SingleChat}
                      layout={AdminLayout}
                    />
                  }
                />
              </Route>

              {/* FINE TUNING */}
              <Route path={"/fine-tuning"}>
                <Route
                  index={true}
                  element={
                    <PrivateRoute
                      component={FineTuningIndex}
                      layout={AdminLayout}
                    />
                  }
                />
              </Route>

              {/* CATEGORY */}
              <Route path={"/categories"}>
                <Route
                  index={true}
                  element={
                    <PrivateRoute
                      component={CategoryIndex}
                      layout={AdminLayout}
                    />
                  }
                />
                <Route
                  path={"create"}
                  element={
                    <PrivateRoute
                      component={CategoryForm}
                      layout={AdminLayout}
                    />
                  }
                />
                <Route
                  path={"show/:categoryId"}
                  element={
                    <PrivateRoute
                      component={CategoryView}
                      layout={AdminLayout}
                    />
                  }
                />
                <Route
                  path={"edit/:categoryId"}
                  element={
                    <PrivateRoute
                      component={CategoryForm}
                      layout={AdminLayout}
                    />
                  }
                />
              </Route>

              {/* SUBSCRIPTIONS */}

              <Route
                path={"/*"}
                element={
                  <CommonRoute
                    component={Page404}
                    authLayout={EmptyLayout}
                    adminLayout={AdminLayout}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(settingsJsonStart())} />
  );
}

export default Base;