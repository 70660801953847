import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileStart } from '../../store/slices/AdminSlice';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import BasicProfileTab from './BasicProfileTab';
import ChangePasswordTab from './ChangePasswordTab';
import TwoStepTab from './TwoStepTab';

const ProfileIndex = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const [activeTab, setActiveTab] = useState("profile");

  const resetTab = () => setActiveTab("profile");

  return (profile.loading ?
    <PageLoader />
    : Object.keys(profile.data).length > 0 ?
      <>
        <div className="flex items-center space-x-4 py-5 lg:py-6">
          <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Profile </h2>
        </div>
        <div className="card p-5 pb-4">
          <div id="tab-wrapper-1" data-active-tab="#tab1-change_password" className="tabs flex flex-col">
            <div className="is-scrollbar-hidden overflow-x-auto">
              <div className="border-b-2 border-slate-150 dark:border-navy-500">
                <div className="tabs-list -mb-0.5 flex">
                  <button
                    className={`tab btn shrink-0 space-x-2 rounded-none border-b-2 px-3 py-2 font-medium ${activeTab === "profile" ?
                      "border-primary dark:border-accent text-primary dark:text-accent-light is-active"
                      : "border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"}
                  `}
                    onClick={() => setActiveTab("profile")}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>
                    <span>Profile</span>
                  </button>
                  <button
                    className={`tab btn shrink-0 space-x-2 rounded-none border-b-2 px-3 py-2 font-medium ${activeTab === "password" ?
                      "border-primary dark:border-accent text-primary dark:text-accent-light is-active"
                      : "border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"}
                    `}
                    onClick={() => setActiveTab("password")}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd"></path>
                    </svg>
                    <span>Change Password</span>
                  </button>
                  <button
                    className={`tab btn shrink-0 space-x-2 rounded-none border-b-2 px-3 py-2 font-medium ${activeTab === "twoStep" ?
                      "border-primary dark:border-accent text-primary dark:text-accent-light is-active"
                      : "border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"}
                    `}
                    onClick={() => setActiveTab("twoStep")}
                  >
                    <i class="fa fa-key" aria-hidden="true"></i>
                    <span>Two Step Authentication</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div className={`tab-content tab-shift-left ${activeTab === "profile" ? "is-active" : ""}`}>
                <BasicProfileTab
                  profile={profile.data}
                  activeTab={activeTab}
                />
              </div>
              <div className={`tab-content tab-shift-left ${activeTab === "password" ? "is-active" : ""}`}>
                <ChangePasswordTab
                  activeTab={activeTab}
                />
              </div>
              <div className={`tab-content tab-shift-left ${activeTab === "twoStep" ? "is-active" : ""}`}>
                <TwoStepTab
                  activeTab={activeTab}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default ProfileIndex;