import React, { useState, useEffect } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionSaveStart } from '../../store/slices/SubscriptionSlice';
import { useNavigate } from 'react-router-dom';

const StripePaymentSec = ({ clientSecret, setClientSecret }) => {
  const dispatch = useDispatch();
  const subscriptionSave = useSelector(state => state.subscription.subscriptionSave);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  // const [email, setEmail] = useState('');
  const [skipRender, setSkipRender] = useState(true);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment success");
          break;
        case "processing":
          setMessage("Payment processing");
          break;
        case "requires_payment_method":
          setMessage("Payment failed");
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, ...response } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000",
      },
    });
    if (!error) {
      dispatch(subscriptionSaveStart({
        amount: response.paymentIntent.amount,
        payment_id: response.paymentIntent.id,
      }));
    }

    if (error)
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  useEffect(() => {
    if (!skipRender && !subscriptionSave.loading && Object.keys(subscriptionSave.data).length > 0) {
      navigate("/subscriptions");
    }
    setSkipRender(false);
  }, [subscriptionSave]);


  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="w-1/2">
        <LinkAuthenticationElement
          id="link-authentication-element"
          // onChange={(e) => setEmail(e.target.value)}
          className="p-4 sm:p-5"
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} className="p-4 sm:p-5" />
        <div className="flex justify-end space-x-2">
          <button
            className="btn min-w-[7rem] rounded-full border font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
            onClick={() => setClientSecret("")}
          >
            Cancel
          </button>
          <button
            disabled={isLoading || !stripe || !elements || subscriptionSave.buttonDisable}
            id="submit"
            type="submit"
            className="btn min-w-[7rem] rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
          >
            <span id="button-text">
              {isLoading || subscriptionSave.buttonDisable ? "Processing" : "Pay now"}
            </span>
          </button>
        </div>
        {/* Show any error or success messages */}
        {message && !skipRender ? <div id="payment-message" className="text-danger">{message}</div> : null}
      </form>
    </>
  );

}

export default StripePaymentSec;