import React, { useEffect } from 'react';
import AdminSidebar from './Header/AdminSidebar';
import AdminHeader from './Header/AdminHeader';
import { useSelector } from 'react-redux';
import ChatBox from '../Chat/ChatBox';
import { motion, AnimatePresence } from "framer-motion";
import AddFineTuning from '../FineTuning/AddFineTuning';
import { useLocation } from 'react-router-dom';

const AuthLayout = ({ children }) => <>{children}</>;

const EmptyLayout = ({ children }) => <>{children}</>;

const AdminLayout = ({ children }) => {
  const { pathname } = useLocation();
  const selectedChats = useSelector(state => state.chat.selectedChats);
  const showAddModal = useSelector(state => state.fineTune.showAddModal);
  const animation = {
    initial: { y: "50%", opacity: 0, scale: 0.5 },
    animate: { y: "0", opacity: 1, scale: 1 },
    transition: { duration: 0.2, ease: "easeOut" },
    exit: { y: "-50%", opacity: 0, scale: 0.1 },
  }
  return (
    <>
      <AdminSidebar />
      <AdminHeader />
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        {children}

        {pathname !== "/profile" ?
          <div style={{
            position: "fixed",
            bottom: "10px",
            left: "var(--main-sidebar-width)",
            display: "flex",
            justifyContent: "left",
            alignItems: "end",
            columnGap: "10px",
            zIndex: 99
          }}>
            <AnimatePresence mode="sync">
              {selectedChats.map((chatId, i) =>
                <motion.div key={chatId} {...animation}>
                  <ChatBox chatId={chatId} key={chatId} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          : null
        }

        {showAddModal ?
          <AddFineTuning />
          : null}
      </main>
    </>
  );
}

export { AuthLayout, EmptyLayout, AdminLayout };