import { all, fork } from "redux-saga/effects";
import AdminSaga from "./AdminSaga";
import CategorySaga from "./CategorySaga";
import SubscriptionSaga from "./SubscriptionSaga";
import ChatSaga from "./ChatSaga";
import FineTuneSaga from "./FineTuneSaga";

export default function* rootSaga() {
    yield all([
        fork(AdminSaga),
        fork(CategorySaga),
        fork(SubscriptionSaga),
        fork(ChatSaga),
        fork(FineTuneSaga),
    ]);
}
