import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordStart } from '../../store/slices/AdminSlice';
import { ButtonLoader } from '../Helper/Loader';

const ForgotPassword = () => {
  const t = useTranslation("forgot");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPassword = useSelector(state => state.admin.forgotPassword);

  const [skipRender, setSkipRender] = useState(true);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required")),
  });

  const handleSubmit = (values) => {
    dispatch(forgotPasswordStart(values));
  }

  useEffect(() => {
    if (!skipRender && !forgotPassword.loading && Object.keys(forgotPassword.data).length > 0)
      navigate("/login");
    setSkipRender(false);
  }, [forgotPassword]);

  return (
    <>
      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <Link to="/login" className="flex items-center space-x-2">
          <img className="h-12 w-12" src={window.location.origin + "/images/app-logo.png"} alt="logo" />
          <p className="text-xl font-semibold uppercase text-slate-700 dark:text-navy-100">
            Haive
          </p>
        </Link>
      </div>
      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img className="w-full" id="hero-image-light" src={window.location.origin + "/images/illustrations/dashboard-check.svg"} alt="image" />
          <img className="w-full hidden" id="hero-image-dark" src={window.location.origin + "/images/illustrations/dashboard-check-dark.svg"} alt="image" />
        </div>
      </div>
      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
            <img className="mx-auto h-16 w-16 lg:hidden" src={window.location.origin + "/images/app-logo.png"} alt="logo" />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                {t("heading")}
              </h2>
            </div>
          </div>
          <div className="mt-16">
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              <FORM>
                <label className="relative flex">
                  <Field
                    name="email"
                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder={t("email.placeholder")}
                    type="text"
                    autoFocus={true}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                    </svg>
                  </span>
                </label>
                <ErrorMessage
                  component={"div"}
                  name="email"
                  className="errorMsg"
                />
                <button
                  type="submit"
                  className="btn mt-10 h-10 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  disabled={forgotPassword.buttonDisable}
                >
                  {forgotPassword.buttonDisable ? <ButtonLoader /> : t("submit_btn.text")}
                </button>
              </FORM>
            </Formik>
            <div className="mt-4 text-center text-xs+">
              <p className="line-clamp-1">
                <span>{t("have_account")}</span>
                <Link
                  to="/login"
                  className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                  {t("login")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ForgotPassword;