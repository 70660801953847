import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    theme: "",
    login: getCommonInitialState(),
    register: getCommonInitialState(),
    forgotPassword: getCommonInitialState(),
    resetPassword: getCommonInitialState(),
    profile: getCommonInitialState(),
    updateProfile: getCommonInitialState(),
    dashboard: getCommonInitialState(),
    generateAPIKey: getCommonInitialState(),
    viewAPIKey: getCommonInitialState(),
    changePassword: getCommonInitialState(),
    settingsJson: getCommonInitialState(),
    emailVerify: getCommonInitialState(),
    updateTwoStep: getCommonInitialState(),
    twoStepAuth: getCommonInitialState(),
    resendOtp: getCommonInitialState(),
    logout: getCommonInitialState(),
};

const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        //Login
        loginStart: (state, action) => { state.login = getCommonStart() },
        loginSuccess: (state, action) => { state.login = getCommonSuccess(action.payload) },
        loginFailure: (state, action) => { state.login = getCommonFailure(action.payload) },

        //Register
        registerStart: (state, action) => { state.register = getCommonStart() },
        registerSuccess: (state, action) => { state.register = getCommonSuccess(action.payload) },
        registerFailure: (state, action) => { state.register = getCommonFailure(action.payload) },

        //Forgot password
        forgotPasswordStart: (state, action) => { state.forgotPassword = getCommonStart() },
        forgotPasswordSuccess: (state, action) => { state.forgotPassword = getCommonSuccess(action.payload) },
        forgotPasswordFailure: (state, action) => { state.forgotPassword = getCommonFailure(action.payload) },

        //Reset Password
        resetPasswordStart: (state, action) => { state.resetPassword = getCommonStart() },
        resetPasswordSuccess: (state, action) => { state.resetPassword = getCommonSuccess(action.payload) },
        resetPasswordFailure: (state, action) => { state.resetPassword = getCommonFailure(action.payload) },

        //Profile
        profileStart: (state, action) => { state.profile = getCommonStart() },
        profileSuccess: (state, action) => { state.profile = getCommonSuccess(action.payload) },
        profileFailure: (state, action) => { state.profile = getCommonFailure(action.payload) },

        //Update Profile
        updateProfileStart: (state, action) => { state.updateProfile = getCommonStart() },
        updateProfileSuccess: (state, action) => { state.updateProfile = getCommonSuccess(action.payload) },
        updateProfileFailure: (state, action) => { state.updateProfile = getCommonFailure(action.payload) },

        //Dashboard
        dashboardStart: (state, action) => { state.dashboard = getCommonStart() },
        dashboardSuccess: (state, action) => { state.dashboard = getCommonSuccess(action.payload) },
        dashboardFailure: (state, action) => { state.dashboard = getCommonFailure(action.payload) },
        dashboardListUpdate: (state, { payload }) => {
            if (state.dashboard.data.recent_chats)
                state.dashboard = getCommonSuccess({
                    ...state.dashboard.data,
                    recent_chats: [payload, ...state.dashboard.data.recent_chats.filter(chat => chat.chat_id !== payload.chat_id).slice(0, 5)],
                })
            else state.dashboard = state.dashboard
        },
        disableDashboardNotify: (state, { payload }) => {
            state.dashboard = getCommonSuccess({
                ...state.dashboard.data,
                recent_chats: state.dashboard.data.recent_chats.map(chat => chat.chat_id === payload ? { ...chat, notify: false } : chat)
            })
        },

        //Generate API key for user
        generateAPIKeyStart: (state, action) => { state.generateAPIKey = getCommonStart() },
        generateAPIKeySuccess: (state, action) => { state.generateAPIKey = getCommonSuccess(action.payload) },
        generateAPIKeyFailure: (state, action) => { state.generateAPIKey = getCommonFailure(action.payload) },

        //View API Key 
        viewAPIKeyStart: (state, action) => { state.viewAPIKey = getCommonStart() },
        viewAPIKeySuccess: (state, action) => { state.viewAPIKey = getCommonSuccess(action.payload) },
        viewAPIKeyFailure: (state, action) => { state.viewAPIKey = getCommonFailure(action.payload) },

        //Change Password
        changePasswordStart: (state, action) => { state.changePassword = getCommonStart() },
        changePasswordSuccess: (state, action) => { state.changePassword = getCommonSuccess(action.payload) },
        changePasswordFailure: (state, action) => { state.changePassword = getCommonFailure(action.payload) },

        // Settings JSON
        settingsJsonStart: (state, action) => { state.settingsJson = getCommonStart() },
        settingsJsonSuccess: (state, action) => { state.settingsJson = getCommonSuccess(action.payload) },
        settingsJsonFailure: (state, action) => { state.settingsJson = getCommonFailure(action.payload) },

        // Email Verification
        emailVerifyStart: (state) => { state.emailVerify = getCommonStart() },
        emailVerifySuccess: (state, { payload }) => { state.emailVerify = getCommonSuccess(payload) },
        emailVerifyFailure: (state, { payload }) => { state.emailVerify = getCommonFailure(payload) },

        // Two Step Auth
        twoStepAuthStart: (state) => { state.twoStepAuth = getCommonStart() },
        twoStepAuthSuccess: (state, { payload }) => { state.twoStepAuth = getCommonSuccess(payload) },
        twoStepAuthFailure: (state, { payload }) => { state.twoStepAuth = getCommonFailure(payload) },

        // Update Two step status
        updateTwoStepStart: (state) => { state.updateTwoStep = getCommonStart() },
        updateTwoStepSuccess: (state, { payload }) => { state.updateTwoStep = getCommonSuccess(payload) },
        updateTwoStepFailure: (state, { payload }) => { state.updateTwoStep = getCommonFailure(payload) },

        // Resend OTP Start
        resendOtpStart: (state) => { state.resendOtp = getCommonStart() },
        resendOtpSuccess: (state, { payload }) => { state.resendOtp = getCommonSuccess(payload) },
        resendOtpFailure: (state, { payload }) => { state.resendOtp = getCommonFailure(payload) },

        // Logout
        logoutStart: (state) => { state.logout = getCommonStart() },
        logoutSuccess: (state, { payload }) => { state.logout = getCommonSuccess(payload) },
        logoutFailure: (state, { payload }) => { state.logout = getCommonFailure(payload) },

    }
});


export const {
    setTheme,
    loginStart,
    loginSuccess,
    loginFailure,
    registerStart,
    registerSuccess,
    registerFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordStart,
    resetPasswordSuccess,
    resetPasswordFailure,
    profileStart,
    profileSuccess,
    profileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    dashboardStart,
    dashboardSuccess,
    dashboardFailure,
    dashboardListUpdate,
    disableDashboardNotify,
    generateAPIKeyStart,
    generateAPIKeySuccess,
    generateAPIKeyFailure,
    viewAPIKeyStart,
    viewAPIKeySuccess,
    viewAPIKeyFailure,
    changePasswordStart,
    changePasswordSuccess,
    changePasswordFailure,
    settingsJsonStart,
    settingsJsonSuccess,
    settingsJsonFailure,
    emailVerifyStart,
    emailVerifySuccess,
    emailVerifyFailure,
    twoStepAuthStart,
    twoStepAuthSuccess,
    twoStepAuthFailure,
    updateTwoStepStart,
    updateTwoStepSuccess,
    updateTwoStepFailure,
    resendOtpStart,
    resendOtpSuccess,
    resendOtpFailure,
    logoutStart,
    logoutSuccess,
    logoutFailure,

} = AdminSlice.actions;

export default AdminSlice.reducer;