import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profileStart } from "../../store/slices/AdminSlice";
import { generateStripeIntentStart } from "../../store/slices/SubscriptionSlice";
import { ButtonLoader } from "../Helper/Loader";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import StripePaymentSec from "./StripePaymentSec";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const SubscriptionForm = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const generateStripeIntent = useSelector(state => state.subscription.generateStripeIntent);
  const settingsJson = useSelector(state => state.admin.settingsJson);
  const theme = useSelector(state => state.admin.theme);
  const [skipRender, setSkipRender] = useState(true);
  const [amount, setAmount] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const stripePromise = loadStripe(settingsJson.data.data.stripe_publishable_key);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount)
      if (amount < 1) getErrorNotificationMessage("Amount should be min 1");
      else dispatch(generateStripeIntentStart({ amount: amount }));
    else getErrorNotificationMessage("Please enter the amount");
  };

  useEffect(() => {
    if (!skipRender && !generateStripeIntent.loading && Object.keys(generateStripeIntent.data).length > 0)
      setClientSecret(generateStripeIntent.data.clientSecret);
    setSkipRender(false);
  }, [generateStripeIntent]);

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          {/* Header */}
          <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Subscriptions </h2>
            <div className="hidden h-full py-1 sm:flex">
              <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
            </div>
            <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
              <li className="flex items-center space-x-2">
                <Link
                  to="/subscriptions"
                  className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                  Subscriptions
                </Link>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </li>
              <li>Buy Subscription</li>
            </ul>
          </div>
          {/*  */}
          <div className="flex items-center justify-start gap-4 pb-2">
            <Link
              to="/subscriptions"
              className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd"></path>
              </svg>
              <span>View Subscriptions</span>
            </Link>
          </div>

          {/* FORM */}
          <div className="card p-5 pb-4">
            <div className="px-2 py-2 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Buy Subscription</h3>
            </div>
            <div className="">
              {!clientSecret ?
                <div className="flex flex-col">
                  <form onSubmit={handleSubmit} >
                    <div className="p-4 sm:p-5">
                      <div className="space-y-5">
                        <div className="w-1/2">
                          <label className="block">
                            <span className="font-medium text-slate-600 dark:text-navy-100">Amount *</span>
                            <input
                              type="number"
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Enter Amount"
                              autoFocus={true}
                              value={amount}
                              onChange={e => setAmount(e.target.value)}
                            />
                          </label>
                          {amount ?
                            <span className="text-tiny+ text-slate-400 dark:text-navy-300">Tokens: {amount / settingsJson.data.data.one_response_value}</span>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div className="flex justify-end space-x-2">
                        <button
                          type="submit"
                          className="btn min-w-[7rem] rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                          disabled={generateStripeIntent.buttonDisable}
                        >
                          {generateStripeIntent.buttonDisable ? <ButtonLoader varient="info" size={5} /> : "Buy"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                : <div className="p-4 sm:p-5">
                  <div className="space-y-5">
                    <Elements options={{
                      clientSecret,
                      appearance: {
                        theme: theme === "dark" ? 'night' : 'stripe',
                        // labels: 'floating'
                      },
                    }} stripe={stripePromise}>
                      <StripePaymentSec clientSecret={clientSecret} setClientSecret={setClientSecret} />
                    </Elements>
                  </div>
                </div>
              }
            </div>
          </div>
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default SubscriptionForm;