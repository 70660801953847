import AdminSlice from "./AdminSlice";
import CategorySlice from "./CategorySlice";
import SubscriptionSlice from "./SubscriptionSlice";
import ChatSlice from "./ChatSlice";
import FineTuneSlice from "./FineTuneSlice";

export const reducers = {
    admin: AdminSlice,
    category: CategorySlice,
    subscription: SubscriptionSlice,
    chat: ChatSlice,
    fineTune: FineTuneSlice,
};