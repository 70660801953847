import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import { emailVerifyStart, resendOtpStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";



const EmailVerification = ({ email, remember, closeVerify }) => {
  const dispatch = useDispatch();
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const resendOtp = useSelector(state => state.admin.resendOtp);
  const [otp, setOTP] = useState("");
  const handleChange = (otp) => setOTP(otp);

  const handleSubmit = e => {
    e.preventDefault();
    console.log(otp, "   ", otp.length);
    if (otp)
      if (otp.length === 6)
        dispatch(emailVerifyStart({
          email,
          remember,
          verification_code: otp,
        }));
      else getErrorNotificationMessage("The verification code should be 6 digits.")
    else getErrorNotificationMessage("The verification code field is required.");
  }

  const handleresendOtp = () => {
    dispatch(resendOtpStart({
      email,
      code_type: "email"
    }));
  }

  return (
    <div className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5 show" role="dialog">
      <div className="modal-overlay absolute inset-0 bg-slate-900/60 backdrop-blur"></div>
      <div className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700">
        <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
          <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
            Email Verification
          </h3>
          <button
            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            onClick={() => closeVerify()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
          <div className="verification-sec">
            <div className="verification-box card">
              <div className="verification-logo-sec">
                <img
                  className="verification-logo"
                  src={
                    window.location.origin + "/images/verify-code-img.svg"}
                />
              </div>
              <h4>
                Please enter the one time password to Verify your account
              </h4>
              <form onSubmit={handleSubmit}>
                <div className="verification-item">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    separator={<span>-</span>}
                    containerStyle="justify-content-center"
                  />
                </div>
                <div className="verification-btn-sec">
                  <button
                    type="button"
                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                    onClick={() => handleresendOtp()}
                    disabled={resendOtp.buttonDisable}
                  >
                    {resendOtp.buttonDisable ? <ButtonLoader size={5} /> : "Resend"}
                  </button>
                  <button
                    type="submit"
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    disabled={emailVerify.buttonDisable}
                  >
                    {emailVerify.buttonDisable ? <ButtonLoader size={5} /> : "Validate"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;

