import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { profileStart } from "../../store/slices/AdminSlice";
import { subscriptionListStart } from "../../store/slices/SubscriptionSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import { Formik, Field, Form } from "formik";
import Pagination from "../Helper/Pagination";

const SubscriptionIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const subscriptionList = useSelector(
    (state) => state.subscription.subscriptionList
  );
  const [filterStatus, setFilterStatus] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: searchParams.get("status") ? searchParams.get("status") : "",
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        subscriptionListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, page, filter]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("status")
      ? `status=${searchParams.get("status")}&`
      : "";
    navigate(`/subscriptions?${params}page=${selected + 1}`);
  };

  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      {/* HEADER */}
      <div className="flex items-center justify-between py-5 lg:py-6">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
            {" "}
            Subscriptions{" "}
          </h2>
          <div className="hidden h-full py-1 sm:flex">
            <div className="h-full w-px bg-slate-300 dark:bg-navy-600">
              &nbsp;
            </div>
          </div>
          <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
            <li className="flex items-center space-x-2">
              <Link
                to="/subscriptions"
                className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
              >
                Subscriptions
              </Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </li>
            <li>View Subscriptions </li>
          </ul>
        </div>
        <div></div>
      </div>
      {subscriptionList.loading ? null : Object.keys(subscriptionList.data)
          .length > 0 ? (
        <div class="grid grid-cols-1 gap-4 transition-all duration-[.25s] sm:mt-5 sm:grid-cols-1 sm:gap-5 lg:mt-6 lg:gap-6">
          <div class="card p-4 sm:col-span-2 sm:p-5">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:gap-6">
              <a>
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-info/10 dark:bg-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"></path>
                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"></path>
                    </svg>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {subscriptionList.data.remaining.replies}
                    </p>
                    <p>Remaining Replies</p>
                  </div>
                </div>
              </a>
              <a>
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-secondary/10 dark:bg-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {subscriptionList.data.remaining.amount}
                    </p>
                    <p>Remaining Amount</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      ) : null}
      {/* FILTER */}
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6 sm:mt-5 lg:mt-6">
        <div className="flex items-center justify-between">
          <Link
            to="/subscriptions/create"
            className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>Buy Subscription</span>
          </Link>
          <Formik
            initialValues={{
              search_key: searchParams.get("search_key")
                ? searchParams.get("search_key")
                : "",
              status: searchParams.get("status")
                ? searchParams.get("status")
                : "",
            }}
            onSubmit={(val) => {
              navigate(
                `/subscriptions?search_key=${val.search_key}&status=${val.status}`
              );
            }}
          >
            {({ values, setFieldValue, resetForm }) => (
              <Form className="form">
                <div className="flex items-center gap-2 justify-between">
                  <div>
                    <label className="block">
                      <Field
                        className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Search by Name"
                        type="text"
                        name="search_key"
                      />
                    </label>
                  </div>
                  <div
                    onClick={() => setFilterStatus((status) => !status)}
                    onBlur={() =>
                      setTimeout(() => {
                        setFilterStatus(false);
                      }, 200)
                    }
                  >
                    <div className="ts-wrapper w-16em single">
                      <div className="ts-control">
                        <input
                          type="select-one"
                          placeholder="Select Status"
                          readOnly={true}
                          value={
                            values.status === "1"
                              ? "Approved"
                              : values.status === "0"
                              ? "Declined"
                              : ""
                          }
                        />
                      </div>
                      <div
                        className="ts-dropdown single"
                        style={{
                          display: filterStatus ? "block" : "none",
                          visibility: "visible",
                        }}
                      >
                        <div
                          role="listbox"
                          tabIndex="-1"
                          className="ts-dropdown-content"
                          id="tomOne-ts-dropdown"
                        >
                          <div
                            data-selectable=""
                            className={`option ${
                              values.status === "" ? "active" : ""
                            }`}
                            onClick={() => setFieldValue("status", "")}
                          >
                            {" "}
                            All{" "}
                          </div>
                          <div
                            data-selectable=""
                            className={`option ${
                              values.status === "1" ? "active" : ""
                            }`}
                            onClick={() => setFieldValue("status", "1")}
                          >
                            {" "}
                            Active{" "}
                          </div>
                          <div
                            data-selectable=""
                            className={`option ${
                              values.status === "0" ? "active" : ""
                            }`}
                            onClick={() => setFieldValue("status", "0")}
                          >
                            {" "}
                            Expired{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="mr-2 btn h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
                      data-tooltip-id="tooltip-success"
                      data-tooltip-content="Search"
                      data-tooltip-place="top"
                    >
                      <i className="fa-solid fa-search"></i>
                    </button>
                    <button
                      type="button"
                      className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
                      onClick={() => {
                        setFieldValue("search_key", "");
                        setFieldValue("status", "");
                        navigate("/subscriptions");
                      }}
                      data-tooltip-id="tooltip-error"
                      data-tooltip-content="Clear"
                      data-tooltip-place="top"
                    >
                      <i className="fa-solid fa-remove"></i>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* TABLE */}
      {subscriptionList.loading ? (
        <PageLoader />
      ) : Object.keys(subscriptionList.data).length > 0 ? (
        <div className="card mt-3">
          <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
            <table className="is-hoverable w-full text-left">
              <thead>
                <tr>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    {" "}
                    #{" "}
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Date
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Payment ID
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Mode
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Amount
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Reward
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Status
                  </th>
                  <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Expiry Date
                  </th>
                  <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {subscriptionList.data.subscriptions.length > 0 ? (
                  subscriptionList.data.subscriptions.map((subscription, i) => (
                    <tr
                      className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                      key={i}
                    >
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {" "}
                        {12 * (page - 1) + (i + 1)}{" "}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {subscription.paid_date}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                        <Link
                          to={`/subscriptions/show/${subscription.unique_id}`}
                          className="text-info"
                        >
                          {subscription.payment_id}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {subscription.payment_mode}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {subscription.amount}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        <Link
                          to="#"
                          className="badge rounded-full border border-info text-info"
                        >
                          {subscription.is_reward == 1 ? "Yes" : "No"}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {subscription.is_active == 1 ? (
                          <div
                            className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15"
                            id="statusBadge156"
                          >
                            <div className="h-2 w-2 rounded-full bg-current"></div>
                            <span id="statusText156">Active</span>
                          </div>
                        ) : (
                          <div
                            className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15"
                            id="statusBadge19"
                          >
                            <div className="h-2 w-2 rounded-full bg-current"></div>
                            <span id="statusText19">Expired</span>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        {subscription.expiry_date}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                        <Link
                          to={`/subscriptions/show/${subscription.unique_id}`}
                          className="btn h-9 w-9 rounded-full bg-secondary p-0 font-medium text-white hover:bg-secondary-focus hover:shadow-lg hover:shadow-secondary/50 focus:bg-secondary-focus active:bg-secondary-focus/90"
                          data-tooltip-id="tooltip-secondary"
                          data-tooltip-content="View"
                          data-tooltip-place="top"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                    <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col justify-end space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
            {subscriptionList.data.subscriptions.length > 0 ? (
              <ol className="pagination space-x-1.5">
                <li>
                  <nav
                    role="navigation"
                    aria-label="Pagination Navigation"
                    className="flex items-center justify-between"
                  >
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700 leading-5">
                          Showing
                          <span className="font-medium">
                            {" "}
                            {12 * (page - 1) + 1}{" "}
                          </span>
                          to
                          <span className="font-medium">
                            {" "}
                            {12 * (page - 1) +
                              subscriptionList.data.subscriptions.length}{" "}
                          </span>
                          of
                          <span className="font-medium">
                            {" "}
                            {subscriptionList.data.total_subscriptions}{" "}
                          </span>
                          results
                        </p>
                      </div>
                      <Pagination
                        page={page}
                        pageCount={Math.ceil(
                          subscriptionList.data.total_subscriptions / 12
                        )}
                        handlePageClick={handlePageClick}
                      />
                    </div>
                  </nav>
                </li>
              </ol>
            ) : null}
          </div>
        </div>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => dispatch(subscriptionListStart())}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default SubscriptionIndex;
