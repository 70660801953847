import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fineTuneSaveStart, setShowAddModal } from "../../store/slices/FineTuneSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { ButtonLoader } from "../Helper/Loader";

const AddFineTuning = () => {
  const dispatch = useDispatch();
  const fineTuneSave = useSelector(state => state.fineTune.fineTuneSave);
  const [skipRender, setSkipRender] = useState(true);
  const [file, setFile] = useState(null);
  const [isFile, setIsFile] = useState(true);

  const isValidUrl = (url) => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name required"),
    import_url: Yup.string().test('is-url-valid', 'URL is not valid', (value) => value ? isValidUrl(value) : true),
  });

  const closeModal = e => { if (e) e.preventDefault(); dispatch(setShowAddModal(false)); }

  const saveTune = values => {
    if (isFile)
      if (file) {
        const { import_url, ...data } = { ...values, file };
        dispatch(fineTuneSaveStart(data));
      } else getErrorNotificationMessage("please select a file to upload");
    else
      if (values.import_url) dispatch(fineTuneSaveStart(values));
      else getErrorNotificationMessage("please enter the url");
  }

  useEffect(() => {
    if (!skipRender && !fineTuneSave.loading && Object.keys(fineTuneSave.data).length > 0) {
      closeModal();
    }
    setSkipRender(false);
  }, [fineTuneSave]);

  return (
    <div className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5 show" role="dialog">
      <div className="modal-overlay absolute inset-0 bg-slate-900/60 backdrop-blur"></div>
      <div className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700">
        <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
          <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
            Add Fine tune
          </h3>
          <button
            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            onClick={closeModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Assumenda incidunt
          </p> */}
          <Formik
            initialValues={{
              name: "",
              description: "",
              import_url: "",
            }}
            validationSchema={validationSchema}
            onSubmit={saveTune}
          >
            <Form noValidate>
              <div className="mt-4 space-y-4">
                {/* <label className="block">
                  {/* <span>File* : </span> *
                  <label
                    className="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  >
                    <input
                      tabIndex="-1"
                      type="file"
                      className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .pdf, .doc, .docx"
                      onChange={e => setFile(e.target.files[0])}
                    />
                    <div className="flex items-center space-x-2">
                      <i className="fa-solid fa-cloud-arrow-up text-base"></i>
                      <span>Choose File</span>
                    </div>
                  </label>
                </label>
                {file ? file.name : null} */}
                <label className="block">
                  <span>Name *:</span>
                  <Field
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    placeholder="Enter name"
                    name="name"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </label>

                <div id="tab-wrapper-1" className="tabs flex flex-col">
                  <div className="is-scrollbar-hidden overflow-x-auto">
                    <div className="tabs-list flex">
                      <button
                        type="button"
                        className={`tab btn shrink-0 rounded-none border-b-2 px-3 py-2 font-medium 
                        ${isFile ? "border-primary dark:border-accent text-primary dark:text-accent-light" : ""}`}
                        onClick={() => setIsFile(true)}
                      >
                        File
                      </button>
                      <button
                        type="button"
                        className={`tab btn shrink-0 rounded-none border-b-2 px-3 py-2 font-medium
                        ${!isFile ? "border-primary dark:border-accent text-primary dark:text-accent-light" : ""} `}
                        onClick={() => setIsFile(false)}
                      >
                        Website URL
                      </button>
                    </div>
                  </div>
                  <div className="pt-4">
                    <div className={`tab-content tab-shift-up ${isFile ? "is-active" : ""}`}>
                      <div className="block">
                        {/* <span>File* : </span> */}
                        <label
                          className="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 mx-3"
                        >
                          <input
                            tabIndex="-1"
                            type="file"
                            className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .json, .jsonl"
                            onChange={e => setFile(e.target.files[0])}
                          />
                          <div className="flex items-center space-x-2">
                            <i className="fa-solid fa-cloud-arrow-up text-base"></i>
                            <span>Choose File</span>
                          </div>
                        </label>
                        <a className="text-primary" href={window.location.origin + "/sample.zip"} download>Sample</a>
                      </div>
                      {file ? file.name : null}
                    </div>
                    <div className={`tab-content tab-shift-up ${!isFile ? "is-active" : ""}`}>
                      <Field
                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="URL Address"
                        name="import_url"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="import_url"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                </div>

                {/* <label className="block">
                  <span>Website URL:</span>
                  <Field
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    placeholder="URL Address"
                    name="import_url"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="import_url"
                    className="errorMsg"
                  />
                </label> */}
                <label className="block">
                  <span>Description:</span>
                  <Field
                    as="textarea"
                    rows="4"
                    placeholder=" Enter Text"
                    className="form-textarea mt-1.5 w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    name="description" />
                </label>
                <div className="space-x-2 text-right">
                  <button
                    type="button"
                    className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                    onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    disabled={fineTuneSave.buttonDisable}
                  >
                    {fineTuneSave.buttonDisable ?
                      <ButtonLoader varient="info" size={5} />
                      : "Save"
                    }
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddFineTuning;