import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { ButtonLoader } from '../Helper/Loader';
import { resetPasswordStart } from '../../store/slices/AdminSlice';

const ResetPassword = () => {
  const t = useTranslation("reset_password");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetPassword = useSelector(state => state.admin.resetPassword);

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("new_password.required"))
      .test("len", t("new_password.invalid"), (val) => val && val.length >= 6),
    password_confirmation: Yup.string()
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref("password")], t("confirm_password.invalid")),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(resetPasswordStart(values));
  }

  useEffect(() => {
    if (!skipRender && !resetPassword.loading && Object.keys(resetPassword.data).length > 0)
      navigate("/login");
    setSkipRender(false);
  }, [resetPassword]);

  return (
    <>
      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <Link to="/login" className="flex items-center space-x-2">
          <img className="h-12 w-12" src={window.location.origin + "/images/app-logo.png"} alt="logo" />
          <p className="text-xl font-semibold uppercase text-slate-700 dark:text-navy-100">
            Haive
          </p>
        </Link>
      </div>
      <div className="hidden w-full place-items-center lg:grid">
        <div className="w-full max-w-lg p-6">
          <img className="w-full" id="hero-image-light" src={window.location.origin + "/images/illustrations/dashboard-check.svg"} alt="image" />
          <img className="w-full hidden" id="hero-image-dark" src={window.location.origin + "/images/illustrations/dashboard-check-dark.svg"} alt="image" />
        </div>
      </div>
      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
            <img className="mx-auto h-16 w-16 lg:hidden" src={window.location.origin + "/images/app-logo.png"} alt="logo" />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                {t("heading")}
              </h2>
              <p className="text-slate-400 dark:text-navy-300">
                {t("message")}
              </p>
            </div>
          </div>
          <div className="mt-16">
            <Formik
              initialValues={{
                reset_token: params.token,
                password: "",
                password_confirmation: ""
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <FORM>
                <label className="relative flex">
                  <Field
                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder={t("new_password.placeholder")}
                    type="password"
                    name="password"
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                    </svg>
                  </span>
                </label>
                <ErrorMessage
                  component={"div"}
                  name="password"
                  className="errorMsg"
                />
                <label className="relative mt-4 flex">
                  <Field
                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder={t("confirm_password.placeholder")}
                    type="password"
                    name="password_confirmation"
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                    </svg>
                  </span>
                </label>
                <ErrorMessage
                  component={"div"}
                  name="password_confirmation"
                  className="errorMsg"
                />
                <button
                  type="submit"
                  className="btn mt-10 h-10 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  disabled={resetPassword.buttonDisable}
                >
                  {resetPassword.buttonDisable ? <ButtonLoader /> : t("save_btn.text")}
                </button>
              </FORM>
            </Formik>
            <div className="mt-4 text-center text-xs+">
              <p className="line-clamp-1">
                <Link
                  to="/login"
                  className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
                >
                  {t("login")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ResetPassword;