import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { profileStart } from "../../store/slices/AdminSlice";
import { chatListStart, disableChatNotify, setHighlightChat, setSelectedChats } from "../../store/slices/ChatSlice";
import PageLoader from "../Helper/PageLoader";
import Pagination from "../Helper/Pagination";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import { Reorder } from "framer-motion";

const ChatIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector(state => state.admin.profile);
  const chatList = useSelector(state => state.chat.chatList);
  const selectedChats = useSelector(state => state.chat.selectedChats);
  const [skipRender, setSkipRender] = useState(true);
  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(chatListStart({
        skip: 12 * (page - 1),
        take: 12,
      }));
  }, [profile, page]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    // let params = searchParams.get("search_key") ? `search_key=${searchParams.get("search_key")}&` : "";
    // params += searchParams.get("status") ? `status=${searchParams.get("status")}&` : "";
    navigate(`/chats?page=${selected + 1}`);
  }

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          {/* Header */}
          <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Chats </h2>
            <div className="hidden h-full py-1 sm:flex">
              <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
            </div>
            <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
              <li className="flex items-center space-x-2">
                <Link
                  to="/chats"
                  className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                  Chats
                </Link>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </li>
              <li>View Chats</li>
            </ul>
          </div>

          {/* Table */}
          {chatList.loading ?
            <PageLoader />
            : Object.keys(chatList.data).length > 0 ?
              <div className='card mt-3'>
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                  <Reorder.Group values={chatList.data.chats}>
                    <table className="is-hoverable w-full text-left">
                      <thead>
                        <tr>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5"> # </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Ref Id
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Date
                          </th>
                          {/* <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Username
                          </th> */}
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Chat Messages
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Age
                          </th>
                          {/* <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Status
                          </th> */}
                          <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {chatList.data.chats.length > 0 ?
                          chatList.data.chats.map((chat, i) =>
                            <Reorder.Item
                              value={chat}
                              dragListener={false}
                              as="tr"
                              className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                              key={chat.chat_id}
                            >
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5"> {(12 * (page - 1)) + (i + 1)} </td>
                              <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                                <Link to={`/chats/show/${chat.chat_id}`} className="text-info">{chat.chat_unique_id}</Link>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {chat.created_at}
                              </td>
                              {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5 text-error">
                                {chat.username}
                              </td> */}
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <Link to="#" className="badge bg-info text-white shadow-soft shadow-info/50 dark:bg-accent dark:shadow-accent/50">
                                  {chat.chat_messages_count}
                                </Link>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <span className="badge rounded-full border border-warning text-warning">{chat.age}</span>
                              </td>
                              {/* <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                {chat.status == 1 ?
                                  <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                    <div className="h-2 w-2 rounded-full bg-current"></div>
                                    <span id="statusText156">Active</span>
                                  </div>
                                  : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                    <div className="h-2 w-2 rounded-full bg-current"></div>
                                    <span id="statusText19">Expired</span>
                                  </div>
                                }
                              </td> */}
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <div className="flex items-center space-x-2">
                                  <Link
                                    to={`/chats/show/${chat.chat_id}`}
                                    className="btn h-9 w-9 rounded-full bg-secondary p-0 font-medium text-white hover:bg-secondary-focus hover:shadow-lg hover:shadow-secondary/50 focus:bg-secondary-focus active:bg-secondary-focus/90"
                                    data-tooltip-id="tooltip-secondary"
                                    data-tooltip-content="View"
                                    data-tooltip-place="top"
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                  <button
                                    className="btn h-9 w-9 rounded-full bg-info p-0 font-medium text-white hover:bg-info-focus hover:shadow-lg hover:shadow-info/50 focus:bg-info-focus active:bg-info-focus/90 relative"
                                    data-tooltip-id="tooltip-info"
                                    data-tooltip-content="Message"
                                    data-tooltip-place="top"
                                    onClick={e => setTimeout(() => {
                                      dispatch(disableChatNotify(chat.chat_id));
                                      dispatch(selectedChats.includes(chat.chat_id) ?
                                        setHighlightChat(chat.chat_id)
                                        : setSelectedChats([...selectedChats, chat.chat_id]))
                                    }, 150)
                                    }
                                    onBlur={() => {
                                      setTimeout(() => {
                                        dispatch(setHighlightChat());
                                      }, 100);
                                    }}
                                  >
                                    {chat.notify ?
                                      <span className="absolute -top-px -right-px flex h-3 w-3 items-center justify-center">
                                        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80"></span>
                                        <span className="inline-flex h-2 w-2 rounded-full bg-secondary"></span>
                                      </span>
                                      : null
                                    }
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                      <path d="M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z"></path>
                                      <path d="M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            </Reorder.Item>
                          )
                          : <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                            <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                              No Data Found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </Reorder.Group>
                </div>

                <div className="flex flex-col justify-end space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
                  {chatList.data.chats.length > 0 ?
                    <ol className="pagination space-x-1.5">
                      <li>
                        <nav role="navigation" aria-label="Pagination Navigation" className="flex items-center justify-between">
                          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                              <p className="text-sm text-gray-700 leading-5">
                                Showing
                                <span className="font-medium"> {(12 * (page - 1)) + 1} </span>
                                to
                                <span className="font-medium"> {(12 * (page - 1)) + chatList.data.chats.length} </span>
                                of
                                <span className="font-medium"> {chatList.data.total_chats} </span>
                                results
                              </p>
                            </div>
                            <Pagination
                              page={page}
                              pageCount={Math.ceil(chatList.data.total_chats / 12)}
                              handlePageClick={handlePageClick}
                            />
                          </div>
                        </nav>
                      </li>
                    </ol>
                    : null
                  }
                </div>
              </div>
              : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(chatListStart())} />
          }
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default ChatIndex;