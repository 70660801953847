import { dispatch } from 'd3-dispatch';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fineTuneDeleteStart, fineTuneSaveStart } from '../../store/slices/FineTuneSlice';
import { getErrorNotificationMessage } from '../Helper/NotificationMessage';
import { Xlsx, Doc, Pdf, Text, Csv, Url, Json } from './Images';

const FileCard = ({ tune }) => {
  const dispatch = useDispatch();
  const fineTuneSave = useSelector(state => state.fineTune.fineTuneSave);
  const fineTuneDelete = useSelector(state => state.fineTune.fineTuneDelete);
  const [skipRender, setSkipRender] = useState(true);
  const [editTune, setEditTune] = useState(false);
  const [url, setUrl] = useState(tune.import_url);

  const closeEditTune = () => {
    setEditTune(false);
    setUrl(tune.import_url);
  }

  const saveUrl = () => {
    try {
      if (url) {
        new URL(url);
      }
      dispatch(fineTuneSaveStart({
        fine_tune_model_id: tune.id,
        import_url: url,
      }));
    } catch (e) {
      getErrorNotificationMessage("URL is not valid");
    }
  }

  const deleteFile = () => {
    dispatch(fineTuneDeleteStart({ fine_tune_model_id: tune.id }));
  }

  useEffect(() => {
    if (!skipRender && !fineTuneSave.loading && Object.keys(fineTuneSave.data).length > 0 && fineTuneSave.data.fine_tune_model.id === tune.id) {
      closeEditTune();
    }
    setSkipRender(false);
  }, [fineTuneSave]);

  const images = {
    xlsx: <Xlsx />,
    doc: <Doc />,
    pdf: <Pdf />,
    txt: <Text />,
    csv: <Csv />,
    link: <Url />,
    json: <Json />,
    jsonl: <Json />,
  }


  return (
    <div className="card grow items-center p-4 text-center sm:p-5" >
      <div className="avatar h-18 w-18">
        {images[tune.file_type]}
      </div>
      <div className="my-2 grow">
        <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
          {tune.name}
        </h3>
        <div>
          <p className="text-xs+ text-primary hover:text-primary-focus dark:text-accent-light dark:hover:text-accent flex justify-center space-x-1 mt-3">
            {editTune ?
              <>
                <input
                  class="form-input h-8 w-full rounded-full border border-slate-300 bg-transparent px-4 py-2 text-xs+ placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  placeholder="URL ADDRESS"
                  type="text"
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                />
                <button
                  className="btn h-7 w-7 rounded-full bg-slate-150 px-0 text-xs+ font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                  onClick={() => saveUrl()}
                  disabled={fineTuneSave.buttonDisable}>
                  {fineTuneSave.buttonDisable && fineTuneSave.data.fine_tune_model_id === tune.id ?
                    <i className="fa fa-circle-notch fa-spin"></i>
                    : <i className="fa fa-check"></i>
                  }
                </button>
                {fineTuneSave.buttonDisable && fineTuneSave.data.fine_tune_model_id === tune.id ?
                  null
                  : <button
                    className="btn h-7 w-7 rounded-full bg-slate-150 px-0 text-xs+ font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                    onClick={() => closeEditTune()}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                }
              </>
              : <>
                {tune.import_url ?
                  <>
                    {tune.import_url}
                    <button
                      className="btn h-7 w-7 rounded-full bg-slate-150 px-0 text-xs+ font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                      onClick={() => setEditTune(true)}>
                      <i className="fa fa-edit"></i>
                    </button>
                  </>
                  : null}
              </>
            }
          </p>
        </div>
      </div>
      <div className="mt-3 flex space-x-1">
        {!tune.import_url ?
          <a
            className="btn h-7 rounded-full bg-slate-150 px-3 text-xs+ font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
            download={true}
            target="_blank"
            href={tune.file}>
            Download
          </a> : null
        }
        <button
          className="btn h-7 w-7 rounded-full bg-slate-150 px-0 text-xs+ font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
          onClick={() => deleteFile(tune.id)}
          disabled={fineTuneDelete.buttonDisable}
        >
          {fineTuneDelete.buttonDisable && fineTuneDelete.data.fine_tune_model_id === tune.id ?
            <i className="fa fa-circle-notch fa-spin"></i>
            : <i className="fa fa-trash"></i>
          }
        </button>
      </div>
    </div>
  );
}

export default FileCard;