import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  generateStripeIntentFailure,
  generateStripeIntentSuccess,
  subscriptionListFailure,
  subscriptionListSuccess,
  subscriptionSaveFailure,
  subscriptionSaveSuccess,
  subscriptionViewFailure,
  subscriptionViewSuccess
} from "../slices/SubscriptionSlice";

function* subscriptionListAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions",
    successNotify: false,
    payload: action.payload,
    success: subscriptionListSuccess,
    failure: subscriptionListFailure,
  });
}

function* subscriptionViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions/show",
    successNotify: false,
    payload: action.payload,
    success: subscriptionViewSuccess,
    failure: subscriptionViewFailure,
  });
}

function* subscriptionSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions/store",
    payload: action.payload,
    success: subscriptionSaveSuccess,
    failure: subscriptionSaveFailure,
  });
}

function* generateStripeIntentAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions/create",
    payload: action.payload,
    successNotify: false,
    success: generateStripeIntentSuccess,
    failure: generateStripeIntentFailure,
  })
}


export default function* SubscriptionSaga() {
  yield all([
    yield takeLatest('subscription/subscriptionListStart', subscriptionListAPI),
    yield takeLatest('subscription/subscriptionViewStart', subscriptionViewAPI),
    yield takeLatest('subscription/subscriptionSaveStart', subscriptionSaveAPI),
    yield takeLatest('subscription/generateStripeIntentStart', generateStripeIntentAPI),
  ]);
}

