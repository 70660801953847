import React from "react";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { urlify } from "./ChatBox";


const SendChat = ({ message }) => {

  return (
    <div className="flex items-start justify-end space-x-2.5 sm:space-x-5">
      <div className="flex flex-col items-end space-y-3.5">
        <div className="ml-4 max-w-lg sm:ml-10">
          <div className="rounded-2xl rounded-tr-none bg-info/10 p-3 text-slate-700 shadow-sm dark:bg-accent dark:text-white">
            {message.file ?
              <div className="my-2">
                <CustomLazyLoad
                  src={message.file}
                />
              </div>
              : null
            }
            <div dangerouslySetInnerHTML={{
              __html: urlify(message.message),
            }} />
          </div>
          <p className="mt-1 ml-auto text-left text-xs text-slate-400 dark:text-navy-300">
            {message.send_at}
          </p>
        </div>
      </div>
      <div className="avatar">
        <img className="rounded-full" src="https://admin.haive.tech/placeholders/support-agent-placeholder.jpg" alt="avatar" />
      </div>
    </div>
  );
}

export default SendChat;