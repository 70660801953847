import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { updateTwoStepStart } from '../../store/slices/AdminSlice';
import { ButtonLoader } from '../Helper/Loader';

const TwoStepTab = ({ activeTab }) => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const updateTwoStep = useSelector(state => state.admin.updateTwoStep);

  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      setInitialTwoStep(
        profile.data.tfa_status === 0 ? false : true
      );
      setTwoStepAuth(
        profile.data.tfa_status === 0 ? false : true
      );
    }
  }, [profile]);

  const changeTwoStepAuth = (values) => {
    dispatch(updateTwoStepStart(values));
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("password required"),
  });


  return (
    <>
      <div className="my-3 flex h-8 items-center justify-between">
        <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base flex items-center">
          <span className="mx-2">Two Step Verification</span>

          <input
            onchange="helpers.toggleCode(event)"
            className="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white"
            type="checkbox"
            checked={twoStepAuth}
            onChange={() => setTwoStepAuth(!twoStepAuth)}
          />
        </h2>
      </div>
      {initialTwoStep != twoStepAuth ?
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => changeTwoStepAuth(values)}
        >
          <Form>
            <div className="w-1/2">
              <label className="block">
                <span className="font-medium text-slate-600 dark:text-navy-100">Password *</span>
                <Field
                  name="password"
                  className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  placeholder="Enter Password"
                  type="password" />
              </label>
              <ErrorMessage
                component={"div"}
                name="password"
                className="errorMsg"
              />
            </div>
            <div className="flex justify-end space-x-2 w-1/2 my-5">
              <button
                type="button"
                className="btn min-w-[7rem] rounded-full border font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                onClick={() => setTwoStepAuth(!twoStepAuth)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                disabled={updateTwoStep.buttonDisable}
              >
                {updateTwoStep.buttonDisable ? <ButtonLoader size={5} /> : "Submit"}
              </button>
            </div>
          </Form>
        </Formik>
        : null
      }
    </>
  );
}

export default TwoStepTab;