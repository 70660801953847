import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  fineTuneList: getCommonInitialState(),
  fineTuneSave: getCommonInitialState(),
  fineTuneDelete: getCommonInitialState(),
  showAddModal: false,
};

const FineTuneSlice = createSlice({
  name: "finetune",
  initialState,
  reducers: {

    //Fine Tune list
    fineTuneListStart: (state, action) => { state.fineTuneList = getCommonStart() },
    fineTuneListSuccess: (state, action) => { state.fineTuneList = getCommonSuccess(action.payload) },
    fineTuneListFailure: (state, action) => { state.fineTuneList = getCommonFailure(action.payload) },

    //Fine Tune Save
    fineTuneSaveStart: (state, action) => { state.fineTuneSave = getCommonStart(action.payload) },
    fineTuneSaveSuccess: (state, action) => { state.fineTuneSave = getCommonSuccess(action.payload) },
    fineTuneSaveFailure: (state, action) => { state.fineTuneSave = getCommonFailure(action.payload) },

    //Fine Tune 
    fineTuneDeleteStart: (state, action) => { state.fineTuneDelete = getCommonStart(action.payload) },
    fineTuneDeleteSuccess: (state, action) => { state.fineTuneDelete = getCommonSuccess(action.payload) },
    fineTuneDeleteFailure: (state, action) => { state.fineTuneDelete = getCommonFailure(action.payload) },

    setShowAddModal: (state, action) => { state.showAddModal = action.payload },

  }
});


export const {
  fineTuneListStart,
  fineTuneListSuccess,
  fineTuneListFailure,
  fineTuneSaveStart,
  fineTuneSaveSuccess,
  fineTuneSaveFailure,
  fineTuneDeleteStart,
  fineTuneDeleteSuccess,
  fineTuneDeleteFailure,
  setShowAddModal,

} = FineTuneSlice.actions;

export default FineTuneSlice.reducer;