import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  chatList: getCommonInitialState(),
  sendMessage: getCommonInitialState(),
  selectedChats: [],
  highlightChat: "",
  socket: null,
  chats: [],
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {

    //Chat Socket
    setChatSocket: (state, action) => { state.socket = action.payload },
    setChats: (state, action) => { state.chats = action.payload },
    addChat: (state, action) => { state.chats = [...state.chats.filter(chat => chat.username != action.payload.username), action.payload] },
    updateChatStatus: (state, { payload }) => {
      state.chats = state.chats.map(chat => chat.username === payload.username ?
        { ...chat, autoMsg: payload.auto }
        : chat
      )
    },
    updateChatSocketStatus: (state, { payload }) => {
      state.chats = state.chats.map(chat => chat.username === payload.username ?
        { ...chat, socketStatus: payload.socketStatus }
        : chat
      )
    },
    updateChatMessages: (state, { payload }) => {
      state.chats = state.chats.map(chat => {
        if (chat.username === payload.username) {
          let dateObj = chat.messages.find(msg => msg.date === payload.message.date);
          if (dateObj) {
            dateObj = {
              ...dateObj, messages: [...dateObj.messages, payload.message.message]
            }
          } else {
            dateObj = { date: payload.message.date, messages: [payload.message.message] };
          }
          return {
            ...chat,
            notify: chat.auto || payload.disableNotify ? chat.notify : !chat.notify,
            messages: [...chat.messages.filter(msg => msg.date !== payload.message.date), dateObj],
          }
        } else
          return chat;
      })
    },
    updateChatSuggtion: (state, { payload }) => {
      state.chats = state.chats.map(chat => chat.username === payload.username ? { ...chat, suggetion: payload.message } : chat)
    },
    unSubscribeChat: (state, { payload }) => {
      state.chats = state.chats.filter(chat => chat.username !== payload.username)
    },

    //Chats
    setSelectedChats: (state, action) => { state.selectedChats = action.payload },
    setHighlightChat: (state, action) => { state.highlightChat = action.payload },

    //Subscription List
    chatListStart: (state) => { state.chatList = getCommonStart() },
    chatListSuccess: (state, action) => { state.chatList = getCommonSuccess(action.payload) },
    chatListFailure: (state, action) => { state.chatList = getCommonFailure(action.payload) },
    chatListUpdate: (state, { payload }) => {
      if (state.chatList.data.chats)
        state.chatList = getCommonSuccess({
          chats: [payload, ...state.chatList.data.chats.filter(chat => chat.chat_id !== payload.chat_id).slice(0, 11)],
          total_chats: state.chatList.data.total_chats,
        })
      else
        state.chatList = state.chatList;
    },
    disableChatNotify: (state, { payload }) => {
      state.chatList = getCommonSuccess({
        ...state.chatList.data,
        chats: state.chatList.data.chats.map(chat => chat.chat_id === payload ? { ...chat, notify: false } : chat)
      })
    }

  }
});


export const {
  setChatSocket,
  chatListStart,
  chatListSuccess,
  chatListFailure,
  chatListUpdate,
  disableChatNotify,
  setSelectedChats,
  setHighlightChat,
  setChats,
  addChat,
  updateChatStatus,
  updateChatSocketStatus,
  updateChatMessages,
  updateChatSuggtion,
  unSubscribeChat

} = ChatSlice.actions;

export default ChatSlice.reducer;