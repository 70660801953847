import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  subscriptionList: getCommonInitialState(),
  subscriptionView: getCommonInitialState(),
  subscriptionSave: getCommonInitialState(),
  generateStripeIntent: getCommonInitialState(),
};

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {

    //Subscription List
    subscriptionListStart: (state) => { state.subscriptionList = getCommonStart() },
    subscriptionListSuccess: (state, action) => { state.subscriptionList = getCommonSuccess(action.payload) },
    subscriptionListFailure: (state, action) => { state.subscriptionList = getCommonFailure(action.payload) },

    //Subscription View
    subscriptionViewStart: (state, action) => { state.subscriptionView = getCommonStart() },
    subscriptionViewSuccess: (state, action) => { state.subscriptionView = getCommonSuccess(action.payload) },
    subscriptionViewFailure: (state, action) => { state.subscriptionView = getCommonFailure(action.payload) },

    //Subscription save
    subscriptionSaveStart: (state, action) => { state.subscriptionSave = getCommonStart() },
    subscriptionSaveSuccess: (state, action) => { state.subscriptionSave = getCommonSuccess(action.payload) },
    subscriptionSaveFailure: (state, action) => { state.subscriptionSave = getCommonFailure(action.payload) },

    //Generate stripe intent
    generateStripeIntentStart: (state, action) => { state.generateStripeIntent = getCommonStart() },
    generateStripeIntentSuccess: (state, action) => { state.generateStripeIntent = getCommonSuccess(action.payload) },
    generateStripeIntentFailure: (state, action) => { state.generateStripeIntent = getCommonFailure(action.payload) },

  }
});


export const {
  subscriptionListStart,
  subscriptionListSuccess,
  subscriptionListFailure,
  subscriptionViewStart,
  subscriptionViewSuccess,
  subscriptionViewFailure,
  subscriptionSaveStart,
  subscriptionSaveSuccess,
  subscriptionSaveFailure,
  generateStripeIntentStart,
  generateStripeIntentSuccess,
  generateStripeIntentFailure,

} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;