import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  fineTuneDeleteFailure,
  fineTuneDeleteSuccess,
  fineTuneListFailure,
  fineTuneListSuccess,
  fineTuneSaveFailure,
  fineTuneSaveSuccess
} from "../slices/FineTuneSlice";


function* fineTuneListAPI(action) {
  yield getCommonSaga({
    apiUrl: "fine_tune_models",
    payload: action.payload,
    successNotify: false,
    success: fineTuneListSuccess,
    failure: fineTuneListFailure,
  });
}

function* fineTuneSaveAPI(action) {
  const fineTuneListData = yield select(state => state.fineTune.fineTuneList.data);
  yield getCommonSaga({
    apiUrl: "fine_tune_models/store",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(fineTuneSaveSuccess(data));
      if (Object.keys(fineTuneListData).length > 0) {
        const item = fineTuneListData.fine_tune_models.find(tune => tune.id === data.fine_tune_model.id);
        store.dispatch(fineTuneListSuccess({
          ...fineTuneListData,
          fine_tune_models: item ? fineTuneListData.fine_tune_models.map(tune => tune.id === data.fine_tune_model.id ? data.fine_tune_model : tune)
            : [data.fine_tune_model, ...fineTuneListData.fine_tune_models]
        }));
      }
    },
    failure: fineTuneSaveFailure,
  });
}

function* fineTuneDeleteAPI(action) {
  const fineTuneListData = yield select(state => state.fineTune.fineTuneList.data);
  yield getCommonSaga({
    apiUrl: "fine_tune_models/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(fineTuneDeleteSuccess());
      store.dispatch(fineTuneListSuccess({
        ...fineTuneListData,
        fine_tune_models: fineTuneListData.fine_tune_models.filter(tune => tune.id !== action.payload.fine_tune_model_id),
      }))
    },
    failure: fineTuneDeleteFailure,
  })
}


export default function* FineTuneSaga() {
  yield all([
    yield takeLatest('finetune/fineTuneListStart', fineTuneListAPI),
    yield takeLatest('finetune/fineTuneSaveStart', fineTuneSaveAPI),
    yield takeLatest('finetune/fineTuneDeleteStart', fineTuneDeleteAPI),
  ]);
}

