import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  chatListFailure,
  chatListSuccess,
  sendMessageFailure,
  sendMessageSuccess
} from "../slices/ChatSlice";


function* chatListAPI(action) {
  yield getCommonSaga({
    apiUrl: "chats",
    payload: action.payload,
    successNotify: false,
    success: chatListSuccess,
    failure: chatListFailure,
  });
}


export default function* ChatSaga() {
  yield all([
    yield takeLatest('chat/chatListStart', chatListAPI),
  ]);
}

