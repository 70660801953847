import React from 'react';

export const Xlsx = () => <svg id="Layer_2" height="70" viewBox="0 0 480 480" width="512" xmlns="http://www.w3.org/2000/svg">
  <g id="Layer_1-2">
    <g id="XLSX">
      <g id="Base">
        <path d="m0 232.9 42.91-42.91v42.91z" fill="#135431" />
        <path d="m480 232.9-42.91-42.91v42.91z" fill="#135431" />
        <path d="m340 0h-274.64c-14.01 0-25.36 11.19-25.36 25v430c0 13.81 11.35 25 25.36 25h349.28c14.01 0 25.36-11.19 25.36-25v-355z" fill="#35bd73" />
        <path d="m340 0 100 100h-100z" fill="#d9ffeb" />
        <path d="m0 232.9h480v180h-480z" fill="#229456" />
        <path d="m440 100v100l-100-100z" fill="#1b9754" />
        <g fill="#d9ffeb">
          <rect height="20" rx="10" width="139.48" x="80" y="96.69" />
          <rect height="20" rx="10" width="230" x="80" y="134.8" />
          <rect height="20" rx="10" width="230" x="80" y="172.9" />
        </g>
      </g>
      <g id="XLSX-2" fill="#fff">
        <path d="m104.5 305.03 17.43-35.45h29.37l-29.81 52.88 30.62 53.76h-29.66l-17.94-36.11-17.94 36.11h-29.59l30.54-53.76-29.74-52.88h29.3l17.43 35.45z" />
        <path d="m186.17 356.45h44.75v19.78h-70.46v-106.64h25.71v86.87z" />
        <path d="m296.76 347.88c0-3.76-1.33-6.69-3.99-8.79s-7.34-4.27-14.03-6.52-12.16-4.42-16.41-6.52c-13.82-6.79-20.73-16.11-20.73-27.98 0-5.91 1.72-11.12 5.16-15.64s8.31-8.03 14.61-10.55c6.3-2.51 13.38-3.77 21.24-3.77s14.54 1.37 20.62 4.1c6.08 2.74 10.8 6.63 14.17 11.68s5.05 10.83 5.05 17.32h-25.63c0-4.35-1.33-7.71-3.99-10.11-2.66-2.39-6.26-3.59-10.8-3.59s-8.22 1.01-10.88 3.04-3.99 4.6-3.99 7.73c0 2.74 1.46 5.21 4.39 7.43s8.08 4.52 15.45 6.88c7.37 2.37 13.43 4.92 18.16 7.65 11.52 6.64 17.29 15.8 17.29 27.47 0 9.33-3.52 16.65-10.55 21.97s-16.68 7.98-28.93 7.98c-8.64 0-16.47-1.55-23.47-4.65-7.01-3.1-12.28-7.35-15.82-12.74s-5.31-11.61-5.31-18.64h25.78c0 5.71 1.48 9.92 4.43 12.63s7.75 4.07 14.39 4.07c4.25 0 7.6-.92 10.07-2.75 2.46-1.83 3.7-4.41 3.7-7.73z" />
        <path d="m375.42 305.03 17.43-35.45h29.37l-29.81 52.88 30.62 53.76h-29.66l-17.94-36.11-17.94 36.11h-29.59l30.54-53.76-29.74-52.88h29.3l17.43 35.45z" />
      </g>
    </g>
  </g>
</svg>;

export const Doc = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }}>
  <path fill="#E2E5E7" d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" />
  <path fill="#B0B7BD" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
  <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
  <path fill="#50BEE8" d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z" />
  <g>
    <path fill="#FFFFFF" d="M92.576,384c-4.224,0-8.832-2.32-8.832-7.936v-72.656c0-4.608,4.608-7.936,8.832-7.936h29.296c58.464,0,57.168,88.528,1.136,88.528H92.576z M100.64,311.072v57.312h21.232c34.544,0,36.064-57.312,0-57.312H100.64z" />
    <path fill="#FFFFFF" d="M228,385.28c-23.664,1.024-48.24-14.72-48.24-46.064c0-31.472,24.56-46.944,48.24-46.944c22.384,1.136,45.792,16.624,45.792,46.944C273.792,369.552,250.384,385.28,228,385.28z M226.592,308.912c-14.336,0-29.936,10.112-29.936,30.32c0,20.096,15.616,30.336,29.936,30.336c14.72,0,30.448-10.24,30.448-30.336C257.04,319.008,241.312,308.912,226.592,308.912z" />
    <path fill="#FFFFFF" d="M288.848,339.088c0-24.688,15.488-45.92,44.912-45.92c11.136,0,19.968,3.328,29.296,11.392c3.456,3.184,3.84,8.816,0.384,12.4c-3.456,3.056-8.704,2.688-11.776-0.384c-5.232-5.504-10.608-7.024-17.904-7.024c-19.696,0-29.152,13.952-29.152,29.552c0,15.872,9.328,30.448,29.152,30.448c7.296,0,14.08-2.96,19.968-8.192c3.952-3.072,9.456-1.552,11.76,1.536c2.048,2.816,3.056,7.552-1.408,12.016c-8.96,8.336-19.696,10-30.336,10C302.8,384.912,288.848,363.776,288.848,339.088z" />
  </g>
  <path fill="#CAD1D8" d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
</svg>;

export const Pdf = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }}>
  <path fill="#E2E5E7" d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" />
  <path fill="#B0B7BD" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
  <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
  <path fill="#F15642" d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z" />
  <g>
    <path fill="#FFFFFF" d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152zM118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z" />
    <path fill="#FFFFFF" d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z" />
    <path fill="#FFFFFF" d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68 h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z" />
  </g>
  <path fill="#CAD1D8" d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
</svg>;

export const Text = () => <svg height="80" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
  <g id="Flat">
    <path d="m12 37v-32a2 2 0 0 1 2-2h26l12 12v22" fill="#d6d6d4" />
    <path d="m52 57v2a2 2 0 0 1 -2 2h-36a2 2 0 0 1 -2-2v-2" fill="#d6d6d4" />
    <path d="m40 3v12h12" fill="#44546c" />
    <rect fill="#f35244" height="20" rx="2" width="46" x="9" y="37" />
    <path d="m46 26h-28a1 1 0 0 1 0-2h28a1 1 0 0 1 0 2z" fill="#44546c" />
    <path d="m46 32h-28a1 1 0 0 1 0-2h28a1 1 0 0 1 0 2z" fill="#44546c" />
    <g fill="#d6d6d4">
      <path d="m26.37 41.61a.61.61 0 0 1 .63.39 1.8 1.8 0 0 1 .17.76 1.55 1.55 0 0 1 -.19.8.61.61 0 0 1 -.56.34h-2.27v8.7a.61.61 0 0 1 -.4.58 2 2 0 0 1 -1.72 0 .62.62 0 0 1 -.39-.58v-8.74h-2.22a.62.62 0 0 1 -.57-.34 1.55 1.55 0 0 1 -.19-.8 1.67 1.67 0 0 1 .18-.76.6.6 0 0 1 .58-.35z" />
      <path d="m32 49.22-2.35 4a.42.42 0 0 1 -.24.19.86.86 0 0 1 -.36.07 1.65 1.65 0 0 1 -.55-.1 2.14 2.14 0 0 1 -.55-.26 2 2 0 0 1 -.41-.4.79.79 0 0 1 -.16-.48.86.86 0 0 1 .15-.45l2.78-4.3-2.67-4.29a.92.92 0 0 1 -.16-.5.8.8 0 0 1 .15-.47 1.41 1.41 0 0 1 .39-.38 2.14 2.14 0 0 1 .53-.25 1.81 1.81 0 0 1 .55-.09.79.79 0 0 1 .76.42l2.14 3.73 2.14-3.73a.79.79 0 0 1 .76-.42 1.79 1.79 0 0 1 .54.09 1.85 1.85 0 0 1 .53.25 1.33 1.33 0 0 1 .4.38.8.8 0 0 1 .15.47.92.92 0 0 1 -.16.5l-2.67 4.28 2.78 4.3a.78.78 0 0 1 0 .93 2 2 0 0 1 -.41.4 2.14 2.14 0 0 1 -.55.26 1.66 1.66 0 0 1 -.56.1.84.84 0 0 1 -.35-.07.42.42 0 0 1 -.24-.19z" />
      <path d="m44.58 41.61a.6.6 0 0 1 .58.35 1.67 1.67 0 0 1 .18.76 1.55 1.55 0 0 1 -.19.8.62.62 0 0 1 -.57.34h-2.22v8.74a.62.62 0 0 1 -.39.58 2 2 0 0 1 -1.72 0 .61.61 0 0 1 -.4-.58v-8.74h-2.22a.61.61 0 0 1 -.56-.34 1.55 1.55 0 0 1 -.19-.8 1.66 1.66 0 0 1 .17-.76.61.61 0 0 1 .58-.35z" />
    </g>
  </g>
</svg>;

export const Csv = () => <svg height="80" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
  <g id="Flat">
    <path d="m12 37v-32a2 2 0 0 1 2-2h26l12 12v22" fill="#d6d6d4" />
    <path d="m52 57v2a2 2 0 0 1 -2 2h-36a2 2 0 0 1 -2-2v-2" fill="#d6d6d4" />
    <path d="m40 3v12h12" fill="#44546c" />
    <rect fill="#7eb457" height="20" rx="2" width="46" x="9" y="37" />
    <path d="m46 26h-28a1 1 0 0 1 0-2h28a1 1 0 0 1 0 2z" fill="#44546c" />
    <path d="m46 32h-28a1 1 0 0 1 0-2h28a1 1 0 0 1 0 2z" fill="#44546c" />
    <g fill="#d6d6d4">
      <path d="m24.58 45.24a3.43 3.43 0 0 0 0-.4 1.09 1.09 0 0 0 -.18-.47 1.16 1.16 0 0 0 -.4-.37 1.51 1.51 0 0 0 -.8-.17 1.42 1.42 0 0 0 -1.07.39 1.71 1.71 0 0 0 -.38 1.22v4.26a1.73 1.73 0 0 0 .38 1.22 1.76 1.76 0 0 0 1.84.23 1.19 1.19 0 0 0 .6-.93 5.19 5.19 0 0 0 .06-.52.63.63 0 0 1 .4-.6 2 2 0 0 1 .84-.16 1.59 1.59 0 0 1 1 .24 1.16 1.16 0 0 1 .3 1 3.31 3.31 0 0 1 -.3 1.44 2.93 2.93 0 0 1 -.86 1.05 3.77 3.77 0 0 1 -1.28.65 5.38 5.38 0 0 1 -1.6.23 5.58 5.58 0 0 1 -1.49-.2 3.49 3.49 0 0 1 -1.23-.66 3.19 3.19 0 0 1 -.83-1.18 4.33 4.33 0 0 1 -.31-1.76v-4.34a4.4 4.4 0 0 1 .31-1.76 3.11 3.11 0 0 1 .83-1.18 3.19 3.19 0 0 1 1.23-.66 5.21 5.21 0 0 1 1.49-.2 5.74 5.74 0 0 1 1.6.21 3.72 3.72 0 0 1 1.28.62 2.94 2.94 0 0 1 .86 1 3 3 0 0 1 .3 1.37 1.2 1.2 0 0 1 -.3 1 1.62 1.62 0 0 1 -.95.23 2 2 0 0 1 -.87-.16.66.66 0 0 1 -.47-.64z" />
      <path d="m31.63 51.28a2.57 2.57 0 0 0 .55-.06 1.62 1.62 0 0 0 .51-.18 1.15 1.15 0 0 0 .36-.33.81.81 0 0 0 .14-.49 1.06 1.06 0 0 0 -.38-.82 4.78 4.78 0 0 0 -.94-.61l-1.23-.58a5.4 5.4 0 0 1 -1.23-.73 3.68 3.68 0 0 1 -.94-1.06 2.94 2.94 0 0 1 -.38-1.58 2.95 2.95 0 0 1 1.3-2.56 3.93 3.93 0 0 1 1.32-.58 5.88 5.88 0 0 1 1.5-.19c.21 0 .5 0 .86.05a6.37 6.37 0 0 1 1.06.19 2.69 2.69 0 0 1 .9.43.88.88 0 0 1 .38.75 1.54 1.54 0 0 1 -.06.38 1.49 1.49 0 0 1 -.17.44 1.07 1.07 0 0 1 -.32.35.7.7 0 0 1 -.45.15 1 1 0 0 1 -.41-.1l-.42-.21a5.37 5.37 0 0 0 -.55-.21 3.15 3.15 0 0 0 -.82-.09 2 2 0 0 0 -1.24.29.84.84 0 0 0 -.37.67.83.83 0 0 0 .38.68 5.33 5.33 0 0 0 .94.53l1.23.55a5.2 5.2 0 0 1 1.23.77 3.88 3.88 0 0 1 .94 1.14 3.44 3.44 0 0 1 .38 1.73 3.74 3.74 0 0 1 -.3 1.54 3.18 3.18 0 0 1 -.83 1.12 3.83 3.83 0 0 1 -1.28.68 5.54 5.54 0 0 1 -1.61.23 6.56 6.56 0 0 1 -1.5-.17 5.62 5.62 0 0 1 -1.24-.47 2.89 2.89 0 0 1 -.84-.67 1.23 1.23 0 0 1 -.3-.78 1.19 1.19 0 0 1 .09-.44 1.85 1.85 0 0 1 .23-.44 1.37 1.37 0 0 1 .35-.33.66.66 0 0 1 .42-.14.88.88 0 0 1 .54.18l.55.41a3.81 3.81 0 0 0 .69.41 2.13 2.13 0 0 0 .96.15z" />
      <path d="m36.17 42.81a.86.86 0 0 1 0-.17.63.63 0 0 1 .15-.41 1.24 1.24 0 0 1 .39-.32 1.91 1.91 0 0 1 .53-.22 1.68 1.68 0 0 1 .54-.08 1.21 1.21 0 0 1 .52.1.61.61 0 0 1 .3.36l2.25 8.26 2.24-8.26a.56.56 0 0 1 .29-.36 1.22 1.22 0 0 1 .53-.1 1.77 1.77 0 0 1 .54.08 2 2 0 0 1 .52.22 1.24 1.24 0 0 1 .39.32.64.64 0 0 1 .16.41 1.2 1.2 0 0 1 0 .17l-3 9.82a1 1 0 0 1 -.6.63 2.58 2.58 0 0 1 -1 .21 2.54 2.54 0 0 1 -1-.21 1 1 0 0 1 -.6-.63z" />
    </g>
  </g>
</svg>;

export const Url = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 512.063 512.063">
  <g>
    <path fill="#FF6600" d="M18.064,193.007l12.864,49.36c2.8,10.832,5.408,20.88,7.216,30.912h0.608
 c2.208-9.84,5.408-20.272,8.608-30.704l15.872-49.552h14.816l15.072,48.56c3.6,11.632,6.416,21.872,8.608,31.712h0.608
 c1.6-9.84,4.24-20.08,7.456-31.504l13.824-48.752h17.472l-31.296,97.12h-16.08l-14.864-46.352
 c-3.408-10.816-6.208-20.448-8.608-31.888h-0.416c-2.4,11.632-5.408,21.68-8.816,32.096l-15.664,46.16H29.328L0,193.007H18.064z"/>
    <path fill="#FF6600" d="M168.064,193.007l12.864,49.36c2.8,10.832,5.408,20.88,7.216,30.912h0.608
 c2.208-9.84,5.408-20.272,8.608-30.704l15.872-49.552h14.816l15.072,48.56c3.6,11.632,6.416,21.872,8.608,31.712h0.608
 c1.6-9.84,4.24-20.08,7.456-31.504l13.824-48.752h17.472l-31.296,97.12h-16.064l-14.864-46.352
 c-3.408-10.816-6.208-20.448-8.608-31.888h-0.4c-2.4,11.632-5.408,21.68-8.816,32.096l-15.664,46.16h-16.032l-29.328-97.12h18.048
 V193.007z"/>
    <path fill="#FF6600" d="M318.112,193.007l12.816,49.36c2.8,10.832,5.408,20.88,7.216,30.912h0.608
 c2.208-9.84,5.456-20.272,8.656-30.704l15.824-49.552h14.864l15.072,48.56c3.6,11.632,6.416,21.872,8.608,31.712h0.608
 c1.6-9.84,4.208-20.08,7.408-31.504l13.856-48.752h17.424l-31.296,97.12h-16.032l-14.864-46.352
 c-3.408-10.816-6.208-20.448-8.608-31.888h-0.4c-2.4,11.632-5.456,21.68-8.848,32.096l-15.632,46.16h-16.064l-29.28-97.12h18.064
 V193.007z"/>
  </g>
  <g>
    <path fill="#22ACDD" d="M366.448,369.007c-41.632,0-81.088-20.128-105.568-53.84l11.616-8.448
 c21.792,30.032,56.912,47.952,93.952,47.952c63.968,0,116.032-52.064,116.032-116.032s-52.064-116.032-116.032-116.032
 c-42.928,0-82.176,23.552-102.432,61.472l-12.656-6.768c22.752-42.592,66.848-69.056,115.088-69.056
 c71.888,0,130.368,58.48,130.368,130.368S438.336,369.007,366.448,369.007z"/>

    <rect x="433.141" y="360.939" transform="matrix(-0.7063 -0.7079 0.7079 -0.7063 552.3334 965.3643)" fill="#22ACDD" width="86.559" height="14.336" />
  </g>
</svg>

export const Json = () => <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  viewBox="0 0 384 384" enableBackground="new 0 0 384 384" >
  <polygon fill="#EFEEEE" points="64,0 64,384 288,384 384,288 384,0 " />
  <polygon fill="#ABABAB" points="288,288 288,384 384,288 " />
  <polygon fill="#DEDEDD" points="192,384 288,384 288,288 " />
  <path fill="#CB5641" d="M0,96v112h256V96L0,96L0,96z" />
  <g>
    <path fill="#FFFFFF" d="M54.88,167.2c0,7.296-1.68,12.496-5.088,15.616c-3.376,3.088-8.56,4.656-15.536,4.656
 c-3.664,0-6.656-0.496-8.992-1.504c-2.336-0.992-4.208-2.384-5.584-4.224s-2.32-3.952-2.816-6.384
 c-0.528-2.416-0.784-4.992-0.784-7.664v-2.992h12.672v2.128c0,3.648,0.384,6.336,1.2,8.112c0.8,1.744,2.432,2.64,4.944,2.64
 s4.16-0.896,4.96-2.64c0.8-1.76,1.2-4.464,1.2-8.112v-48.976H54.88C54.88,117.856,54.88,167.2,54.88,167.2z"/>
    <path fill="#FFFFFF" d="M80.8,167.008c0,1.6,0.144,3.056,0.384,4.352c0.256,1.312,0.736,2.416,1.44,3.312
 c0.704,0.912,1.664,1.616,2.848,2.128c1.168,0.496,2.656,0.768,4.448,0.768c2.112,0,4.016-0.688,5.696-2.064
 c1.696-1.376,2.56-3.52,2.56-6.384c0-1.536-0.224-2.864-0.624-3.984c-0.416-1.12-1.104-2.128-2.064-3.008
 c-0.976-0.912-2.24-1.712-3.808-2.448s-3.504-1.488-5.792-2.256c-3.072-1.024-5.728-2.16-7.968-3.376
 c-2.24-1.2-4.112-2.624-5.616-4.272c-1.504-1.632-2.624-3.52-3.312-5.664c-0.72-2.16-1.056-4.624-1.056-7.456
 c0-6.784,1.888-11.824,5.664-15.152s8.976-4.992,15.568-4.992c3.056,0,5.904,0.336,8.496,1.008s4.832,1.744,6.72,3.264
 c1.872,1.504,3.36,3.424,4.4,5.744c1.056,2.336,1.6,5.136,1.6,8.4v1.92H97.12c0-3.264-0.576-5.776-1.712-7.552
 c-1.152-1.744-3.088-2.64-5.776-2.64c-1.536,0-2.816,0.24-3.824,0.672c-1.008,0.448-1.84,1.04-2.448,1.776s-1.04,1.616-1.264,2.576
 c-0.224,0.96-0.336,1.952-0.336,2.976c0,2.128,0.448,3.888,1.344,5.328c0.896,1.456,2.816,2.784,5.76,3.984l10.656,4.608
 c2.624,1.152,4.752,2.352,6.416,3.616s2.992,2.592,3.984,4.032s1.712,3.008,2.08,4.752c0.384,1.712,0.576,3.648,0.576,5.744
 c0,7.232-2.096,12.496-6.304,15.792c-4.192,3.296-10.032,4.96-17.504,4.96c-7.808,0-13.408-1.696-16.768-5.088
 s-5.04-8.256-5.04-14.592v-2.784H80.8L80.8,167.008L80.8,167.008z"/>
    <path fill="#FFFFFF" d="M124.528,137.776c0.464-4.368,1.536-8.144,3.152-11.28c1.648-3.12,4-5.584,7.056-7.344
 c3.056-1.744,7.2-2.64,12.368-2.64c5.184,0,9.312,0.896,12.384,2.64c3.072,1.76,5.424,4.224,7.04,7.344
 c1.648,3.136,2.688,6.912,3.184,11.28c0.464,4.384,0.72,9.168,0.72,14.352c0,5.232-0.256,10.048-0.72,14.384
 c-0.496,4.352-1.552,8.096-3.184,11.232c-1.632,3.136-3.968,5.536-7.04,7.2c-3.072,1.664-7.2,2.496-12.384,2.496
 c-5.168,0-9.312-0.848-12.368-2.496c-3.056-1.664-5.424-4.064-7.056-7.2c-1.632-3.136-2.688-6.88-3.152-11.232
 c-0.48-4.336-0.72-9.152-0.72-14.384C123.808,146.96,124.048,142.176,124.528,137.776z M137.968,163.888
 c0.224,3.232,0.672,5.856,1.344,7.872s1.632,3.488,2.88,4.416c1.248,0.928,2.912,1.392,4.944,1.392c2.064,0,3.696-0.464,4.96-1.392
 c1.248-0.944,2.208-2.4,2.896-4.416c0.656-2.016,1.104-4.64,1.344-7.872c0.224-3.232,0.336-7.136,0.336-11.744
 s-0.112-8.512-0.336-11.712c-0.24-3.2-0.688-5.824-1.344-7.872c-0.688-2.048-1.648-3.536-2.896-4.448
 c-1.248-0.944-2.896-1.408-4.96-1.408c-2.032,0-3.68,0.464-4.944,1.408c-1.248,0.928-2.208,2.4-2.88,4.448
 c-0.672,2.048-1.12,4.672-1.344,7.872s-0.336,7.104-0.336,11.712S137.744,160.656,137.968,163.888z"/>
    <path fill="#FFFFFF" d="M217.024,164.816h0.192v-46.96h12.672V186.4H214.16l-16.912-48h-0.192v48h-12.688v-68.544h15.936
 L217.024,164.816z"/>
  </g>
</svg >;