import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { profileStart } from '../../store/slices/AdminSlice';
import { subscriptionViewStart } from '../../store/slices/SubscriptionSlice';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';

const SubscriptionView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const profile = useSelector(state => state.admin.profile);
  const subscriptionView = useSelector(state => state.subscription.subscriptionView);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(subscriptionViewStart({ subscription_unique_id: params.subscriptionUniqueId }))
  }, [profile]);

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          {/* Header */}
          <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Subscriptions </h2>
            <div className="hidden h-full py-1 sm:flex">
              <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
            </div>
            <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
              <li className="flex items-center space-x-2">
                <Link
                  to="/subscriptions"
                  className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                  Subscriptions
                </Link>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </li>
              <li>View Subscription</li>
            </ul>
          </div>
          {/*  */}
          <div className="flex items-center justify-start gap-4 pb-2">
            <Link
              to="/subscriptions/create"
              className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd"></path>
              </svg>
              <span>Buy Subscription</span>
            </Link>
            <Link
              to="/subscriptions"
              className="btn space-x-2 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd"></path>
              </svg>
              <span>View Subscriptions</span>
            </Link>
          </div>
          {/* VIEW */}
          {subscriptionView.loading ?
            <PageLoader />
            : Object.keys(subscriptionView.data).length > 0 ?
              <div className="card px-4 pb-4 sm:px-5">
                <div className="grid grid-cols-2 gap-4 sm:gap-5 lg:gap-6">

                  <div>
                    <div className="mt-2">
                      <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500">
                        <table className="w-full text-left">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Unique id
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.unique_id}
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Created at
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.paid_date}
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Payment mode
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.payment_mode}
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Status
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.status == 1 ?
                                  <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                    <div className="h-2 w-2 rounded-full bg-current"></div>
                                    <span id="statusText156">Active</span>
                                  </div>
                                  : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                    <div className="h-2 w-2 rounded-full bg-current"></div>
                                    <span id="statusText19">Expired</span>
                                  </div>
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Amount
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.amount}
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Remaining tokens
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.remaining_tokens}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="mt-2">
                      <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500">
                        <table className="w-full text-left">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Payment id
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.payment_id}
                              </td>
                            </tr>

                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Expire on
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.expiry_date}
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Is reward
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.is_reward == 1 ?
                                  <div class="badge rounded-full bg-success text-white">
                                    Yes
                                  </div>
                                  : <div class="badge rounded-full bg-error text-white">
                                    No
                                  </div>
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Payment status
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.status == 1 ?
                                  <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                    <div className="h-2 w-2 rounded-full bg-current"></div>
                                    <span id="statusText156">{subscriptionView.data.subscription.status_formatted}</span>
                                  </div>
                                  : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                    <div className="h-2 w-2 rounded-full bg-current"></div>
                                    <span id="statusText19">{subscriptionView.data.subscription.status_formatted}</span>
                                  </div>
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Total tokens
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {subscriptionView.data.subscription.total_tokens}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              : <SomethingWentWrong
                buttonText="Retry"
                handleClick={() => dispatch(subscriptionViewStart({ subscription_unique_id: params.subscriptionUniqueId }))}
              />
          }
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default SubscriptionView;