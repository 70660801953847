import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorNotificationMessage } from '../components/Helper/NotificationMessage';
import { dashboardListUpdate } from '../store/slices/AdminSlice';
import {
  chatListUpdate,
  setChats,
  unSubscribeChat,
  updateChatMessages,
  updateChatSocketStatus,
  updateChatStatus,
  updateChatSuggtion
} from '../store/slices/ChatSlice';

const useSocket = () => {
  const dispatch = useDispatch();
  const socket = useSelector(state => state.chat.socket);
  const chats = useSelector(state => state.chat.chats);

  useEffect(() => {
    if (socket)
      socket.onmessage = function (event) {
        const data = JSON.parse(event.data);
        console.log(data);
        switch (data.status) {
          case "subscribe": {
            dispatch(updateChatSocketStatus(data));
            if (data.socketStatus === 2) getErrorNotificationMessage("Chat is already opened in another device");
            return;
          };
          case "reconnect": {
            dispatch(updateChatSocketStatus(data));
            if (data.socketStatus === 2) getErrorNotificationMessage("Chat got active in another device");
            return;
          }
          case "update": {
            dispatch(updateChatStatus(data));
            return;
          };
          case "message": {
            dispatch(updateChatMessages(data));
            if (data.chat_formatted) updateList(data.chat_formatted);
            return;
          };
          case "suggetion": {
            dispatch(updateChatSuggtion(data));
            if (data.chat_formatted) updateList(data.chat_formatted);
            return;
          };
          case "unsubscribe": {
            dispatch(unSubscribeChat(data));
            return;
          };
          case "message_list_notify": {
            updateList(data.chat_formatted)
            return;
          }
        }
      }
  }, [socket]);

  const updateList = chat_formatted => {
    dispatch(chatListUpdate(chat_formatted));
    dispatch(dashboardListUpdate(chat_formatted));
  }

  const subscribe = (username) => socket ?
    socket.send(JSON.stringify({ action: "subscribe", type: "support", username: username })) : null;

  const reconnect = (username) => socket ?
    socket.send(JSON.stringify({ action: "reconnect", type: "support", username })) : null;


  const update = (username) => socket ?
    socket.send(JSON.stringify({ action: "update", username })) : null;


  const sendMessage = (username, message) => socket ?
    socket.send(JSON.stringify({ action: "message", username, message })) : null;


  const unSubscribe = (username) => {
    if (username) {
      socket.send(JSON.stringify({ action: "unsubscribe", username: username }));
    }
  }

  return {
    subscribe,
    reconnect,
    update,
    sendMessage,
    unSubscribe
  };

}

export default useSocket;