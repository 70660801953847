import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileStart } from '../../store/slices/AdminSlice';
import { fineTuneListStart, setShowAddModal } from '../../store/slices/FineTuneSlice';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import FileCard from './FileCard';

const FineTuningIndex = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const fineTuneList = useSelector(state => state.fineTune.fineTuneList);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(fineTuneListStart());
  }, [profile]);

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          {/* HEADER */}
          <div className="flex items-center justify-between py-5 lg:py-6">
            <div className="flex items-center space-x-4">
              <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Fine Tunings </h2>
              <div className="hidden h-full py-1 sm:flex">
                <div className="h-full w-px bg-slate-300 dark:bg-navy-600">&nbsp;</div>
              </div>
              <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
                <li className="flex items-center space-x-2">
                  <Link
                    to="/fine-tuning"
                    className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                    Fine Tuning
                  </Link>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                  </svg>
                </li>
                <li>View Fine Tuning </li>
              </ul>
            </div>
            <div>
            </div>
          </div>

          {/* FILTER */}
          <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
            <div className="flex items-center justify-between">
              <button
                className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
                onClick={() => dispatch(setShowAddModal(true))}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 01-.375.65 2.249 2.249 0 000 3.898.75.75 0 01.375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 17.625v-3.026a.75.75 0 01.374-.65 2.249 2.249 0 000-3.898.75.75 0 01-.374-.65V6.375zm15-1.125a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0v.75a.75.75 0 001.5 0v-.75zm-.75 3a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zM6 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H6.75A.75.75 0 016 12zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd"></path>
                </svg>
                <span>Add Fine Tuning</span>
              </button>
            </div >
          </div >

          {/* List */}
          {fineTuneList.loading ?
            <PageLoader />
            : Object.keys(fineTuneList.data).length > 0 ?
              <div className="grid grid-cols-1 gap-4 mt-3 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                {fineTuneList.data.fine_tune_models.map((tune, i) =>
                  <FileCard key={i} tune={tune} />
                )}
              </div>
              : <SomethingWentWrong buttonText='Retry' handleClick={() => dispatch(fineTuneListStart())} />
          }
        </>
        : <SomethingWentWrong buttonText='Retry' handleClick={() => dispatch(profileStart())} />
  );
}

export default FineTuningIndex;