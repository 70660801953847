import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dashboardStart, disableDashboardNotify, profileStart } from '../../store/slices/AdminSlice';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import MapChart from './MapChart';
import TicketsChart from './TicketsChart';
import { setHighlightChat, setSelectedChats } from '../../store/slices/ChatSlice';
import { Reorder } from 'framer-motion';

const DashboardIndex = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.admin.profile);
  const dashboard = useSelector(state => state.admin.dashboard);
  const selectedChats = useSelector(state => state.chat.selectedChats);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(dashboardStart());
  }, [profile]);

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Dashboard </h2>
          </div>
          {dashboard.loading ?
            <PageLoader />
            : Object.keys(dashboard.data).length > 0 ?
              <>

                <div className="mt-4 grid grid-cols-12 gap-4 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
                  <div className="col-span-12 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:col-span-4 lg:grid-cols-1 lg:gap-6 xl:col-span-3">
                    <div className="card pb-5" style={{ maxHeight: "300px" }}>
                      <div className="mt-3.5 flex grow items-baseline justify-between px-4 sm:px-5">
                        <div>
                          <p className="font-medium">Chats</p>
                          {/* <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                            823
                          </p> */}
                        </div>
                        <div className="badge space-x-1 rounded-full bg-success/10 py-1 px-1.5 text-success dark:bg-success/15">
                          <span className="text-success"> Last 10 days </span>
                        </div>
                      </div>
                      <div className='ax-transparent-gridline'>
                        <TicketsChart tickets={dashboard.data.chat_messages} />
                      </div>
                    </div>
                    <div className="card pb-5" style={{ maxHeight: "300px" }}>
                      <div className="mt-3.5 flex grow items-baseline justify-between px-4 sm:px-5">
                        <div>
                          <p className="font-medium">Fine Tunings</p>
                          {/* <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                            823
                          </p> */}
                        </div>
                        <div className="badge space-x-1 rounded-full bg-success/10 py-1 px-1.5 text-success dark:bg-success/15">
                          <span className="text-success"> Last 10 days </span>
                        </div>
                      </div>
                      <div className='ax-transparent-gridline'>
                        <TicketsChart tickets={dashboard.data.fine_tune_models} />
                      </div>
                    </div>
                  </div>
                  <div className="card col-span-12 lg:col-span-8 xl:col-span-9">
                    <div className="mt-3 flex items-center justify-between px-4 sm:px-5">
                      <h2 className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                        Recent Chats
                      </h2>
                    </div>

                    <div className="scrollbar-sm mt-5 min-w-full overflow-x-auto">
                      {dashboard.data.recent_chats ?
                        <Reorder.Group values={dashboard.data.recent_chats}>
                          <table className="is-hoverable w-full text-left">
                            <tbody>
                              {dashboard.data.recent_chats.map((chat, i) =>
                                <Reorder.Item
                                  value={chat}
                                  dragListener={false}
                                  as="tr"
                                  className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500"
                                  key={chat.chat_id}
                                >
                                  <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                                    <Link to={`/chats/show/${chat.chat_id}`} className="text-info">{chat.chat_unique_id}</Link>
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {chat.created_at}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    <Link to="#" className="badge bg-info text-white shadow-soft shadow-info/50 dark:bg-accent dark:shadow-accent/50 w-100">
                                      {chat.chat_messages_count}
                                    </Link>
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    <span className="badge rounded-full border border-warning text-warning">{chat.age}</span>
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    {chat.status == 1 ?
                                      <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                        <div className="h-2 w-2 rounded-full bg-current"></div>
                                        <span id="statusText156">Active</span>
                                      </div>
                                      : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                        <div className="h-2 w-2 rounded-full bg-current"></div>
                                        <span id="statusText19">Expired</span>
                                      </div>
                                    }
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                    <div className="flex items-center space-x-2">
                                      {/* <Link
                                  to={`/chats/show/${chat.chat_id}`}
                                  className="btn h-9 w-9 rounded-full bg-secondary p-0 font-medium text-white hover:bg-secondary-focus hover:shadow-lg hover:shadow-secondary/50 focus:bg-secondary-focus active:bg-secondary-focus/90"
                                  data-tooltip-id="tooltip-secondary"
                                  data-tooltip-content="View"
                                  data-tooltip-place="top"
                                >
                                  <i className="fa-solid fa-eye"></i>
                                </Link> */}
                                      <button
                                        className="btn h-9 w-9 rounded-full bg-info p-0 font-medium text-white hover:bg-info-focus hover:shadow-lg hover:shadow-info/50 focus:bg-info-focus active:bg-info-focus/90 relative"
                                        data-tooltip-id="tooltip-info"
                                        data-tooltip-content="Message"
                                        data-tooltip-place="top"
                                        onClick={e => setTimeout(() => {
                                          dispatch(disableDashboardNotify(chat.chat_id));
                                          dispatch(selectedChats.includes(chat.chat_id) ?
                                            setHighlightChat(chat.chat_id)
                                            : setSelectedChats([...selectedChats, chat.chat_id]))
                                        }, 150)
                                        }
                                        onBlur={() => {
                                          setTimeout(() => {
                                            dispatch(setHighlightChat());
                                          }, 100);
                                        }}
                                      >
                                        {chat.notify ?
                                          <span className="absolute -top-px -right-px flex h-3 w-3 items-center justify-center">
                                            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80"></span>
                                            <span className="inline-flex h-2 w-2 rounded-full bg-secondary"></span>
                                          </span>
                                          : null
                                        }
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                          <path d="M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z"></path>
                                          <path d="M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </td>
                                </Reorder.Item>
                              )}
                            </tbody>
                          </table>
                        </Reorder.Group>
                        : null
                      }
                    </div>
                  </div>
                </div>

              </>
              : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(dashboardStart())} />
          }
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default DashboardIndex;