import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useLogout from '../../../hooks/useLogout';
import useSocket from '../../../hooks/useSocket';
import { profileStart, setTheme } from '../../../store/slices/AdminSlice';
import { setChatSocket } from '../../../store/slices/ChatSlice';
import { socketUrl } from '../../../Environment';

const AdminHeader = () => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const theme = useSelector(state => state.admin.theme);
  const profile = useSelector(state => state.admin.profile);
  const socket = useSelector(state => state.chat.socket);
  const [showUser, setShowUser] = useState(false);
  const socketer = useSocket();

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      const webSocket = new WebSocket(`${socketUrl}?key=${profile.data.api_key}`);
      dispatch(setChatSocket(webSocket));
    }
  }, [profile]);

  useEffect(() => {
    console.log(socket);
    return () => {
      if (socket) {
        console.log("Closed");
        socket.close();
      }
    }
  }, [socket]);

  return (
    <nav className="header print:hidden">
      {/* App Header  */}
      <div
        className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden"
      >
        {/* Header Items */}
        <div className="flex w-full items-center justify-between">
          {/* Left: Sidebar Toggle Button */}
          <div className="h-7 w-7">
            <button
              className="sidebar-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80"
              onClick={() => { document.body.classList.toggle("is-sidebar-open"); }}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          {/* Right: Header buttons */}
          <div className="-mr-1.5 flex items-center space-x-2">


            {/* Dark Mode Toggle */}
            <button
              className="darkmode-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              onClick={() => dispatch(setTheme(theme === "dark" ? "" : "dark"))}
            >
              <svg
                className="darkmode-moon h-6 w-6 text-amber-400"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M11.75 3.412a.818.818 0 01-.07.917 6.332 6.332 0 00-1.4 3.971c0 3.564 2.98 6.494 6.706 6.494a6.86 6.86 0 002.856-.617.818.818 0 011.1 1.047C19.593 18.614 16.218 21 12.283 21 7.18 21 3 16.973 3 11.956c0-4.563 3.46-8.31 7.925-8.948a.818.818 0 01.826.404z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="darkmode-sun h-6 w-6 text-amber-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {/* Monochrome Mode Toggle */}
            {/* <button
              className="monochrome-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              onClick={() => { document.body.classList.toggle("is-monochrome"); }}
            >
              <i
                className="fa-solid fa-palette bg-gradient-to-r from-sky-400 to-blue-600 bg-clip-text text-lg font-semibold text-transparent"
              ></i>
            </button> */}

            {/* Profile */}
            {!profile.loading && Object.keys(profile.data).length > 0 ?
              <div id="profile-wrapper" className="flex">
                <button
                  id="profile-ref"
                  className={`avatar h-12 w-12 ${showUser ? "active" : ""}`}
                  onClick={() => setShowUser(!showUser)}
                  onBlur={() => {
                    setTimeout(() => {
                      setShowUser(false);
                    }, 200);
                  }}
                >
                  <img
                    className="rounded-full"
                    src={profile.data.picture}
                    alt="avatar"
                  />
                  <span
                    className="absolute right-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"
                  ></span>
                </button>
                <div id="profile-box" className={`popper-root fixed ${showUser ? "show" : ""}`} data-boundary="viewport"
                  style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-136px, 10px)" }}
                  data-popper-placement="left-start">
                  <div className="popper-box w-64 rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-600 dark:bg-navy-700">
                    <div className="flex items-center space-x-4 rounded-t-lg bg-slate-100 py-5 px-4 dark:bg-navy-800">
                      <div className="avatar h-14 w-14">
                        <img className="rounded-full" src={profile.data.picture} alt="avatar" />
                      </div>
                      <div>
                        <Link to="#" className="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light">
                          {profile.data.name}
                        </Link>
                        <p className="text-xs text-slate-400 dark:text-navy-300">
                          {profile.data.email}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col pt-2 pb-5">
                      <Link
                        to="/profile"
                        className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600">
                        <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-warning text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                          </svg>
                        </div>

                        <div>
                          <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                            Profile
                          </h2>
                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            Your profile setting
                          </div>
                        </div>
                      </Link>
                      <div className="mt-3 px-4">
                        <button
                          onClick={() => logout()}
                          className="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                          </svg>
                          <span>Logout</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null
            }
          </div>
        </div>
      </div>
    </nav >
  );
}

export default AdminHeader;